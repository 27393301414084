<script>
import Layout from "../../layouts/horizontal";
import { mapGetters, mapActions } from 'vuex';
import PageHeader from "@/components/page-header";
import http from '@/explorer-client'
import httpFxHedge from '@/service-clients/fx-hedge'
import Multiselect from "vue-multiselect";
import { mapState } from 'vuex';
import {Chart} from 'highcharts-vue'
//import { debounce } from "debounce"
import { camelCase } from 'lodash';
import { isNumber } from "highcharts";
import Swal from "sweetalert2";
import moment from 'moment'

const colors = [
'#3366CC',
'#DC3912',
'#FF9900',
'#109618',
'#990099',
'#3B3EAC',
'#0099C6',
'#DD4477',
'#66AA00',
'#B82E2E',
'#316395',
'#994499',
'#22AA99',
'#AAAA11',
'#6633CC',
'#E67300',
'#8B0707',
'#329262',
'#5574A6',
'#3B3EAC'
];


const camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(v => camelizeKeys(v));
    } else if (obj != null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: camelizeKeys(obj[key]),
        }),
        {},
      );
    }
    return obj;
  };

const createDefaultPortfolioSettings = () => ({
  HoldingAssetRiskToleranceMax: 70,
  HoldingAssetTarget: 50,
  HoldingAssetRiskToleranceMin: 30,
  HoldingLiabilitiesRiskToleranceMax: -30,
  HoldingLiabilitiesTarget: -50,
  HoldingLiabilitiesRiskToleranceMin: -70,
  OverallPortfolioLimits: false,

  RiskPortfolioFormRiskToleranceMode: 'WithRiskOffset',

  ICHoldingAssetRiskToleranceMax: 100,
  ICHoldingAssetTarget: 50,
  ICHoldingAssetRiskToleranceMin: 0,
  ICHoldingLiabilitiesRiskToleranceMax: 0,
  ICHoldingLiabilitiesTarget: -50,
  ICHoldingLiabilitiesRiskToleranceMin: -100
});

const _getCurrencyRiskFactor = (curr) => {
  if(curr === 'USD') return	1;
  if(curr === 'EUR') return	1;
  if(curr === 'GBP') return	1;
  if(curr === 'JPY') return	1;
  if(curr === 'CHF') return	1;
  if(curr === 'CAD') return	1;
  if(curr === 'AUD') return	1;
  if(curr === 'NZD') return	1;
  if(curr === 'SEK') return	1;
  if(curr === 'NOK') return	1;
  if(curr === 'CNY') return	2;
  if(curr === 'BRL') return	2;
  if(curr === 'MXN') return	2;
  if(curr === 'ZAR') return	2;
  return 0;
};

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    Highcharts:Chart
  },
  created() {
    const tabName = localStorage.getItem('fx_hedge_index_tab');
    if(tabName && tabName !== 'commodities') {
      this.selectedTab = tabName;
    }
   
    this.load();
  },
  data() {
    return {
      riskPortfolioFormRiskToleranceModeOptions: [
        { text: 'Simple', value: 'Simple' },
        { text: 'With Risk Offset', value: 'WithRiskOffset' }
      ],
      selectedTab: 'hedge',
      title: "C8 Hedge",
      portfolios: [],
      selectedPortfolioId: null,
      selectingPortfolio: false,
      portfolioName: null,
      portfolioExternalSymbol: null,
      portfolioDescription: null,
      isDeleting: false,
      newPortfolioObj: null,
      newPortfolioDialogVisible: null,
      chartsLoading: false,
      isLoadCurrencies: false,
      isLoadUser: false,
      isSaving: false,
      currentUser: null,
      recalculating: false,
      defaultCurrency: null,
      chartsCurrency: null,
      currencySettings: [],
      currencies: [],
      ccyExposures:[],
      commodities: [],
      additionalUserHedgeData: [],
      editedCcyExposures: [],
      chartOptions: [],
      fxHedgeData: null,
      chartsData: null,
      showEditCurrencySettings: false,
      showEditPortfolioSettings: false,
      editedCurrency: null,
      portfolioSettings: null,
      breadcrumbs: [
      ],
      isPortfolioHoldingAssetInvalid: false,
      isPortfolioHavingLiabilitiesInvalid: false,
      isICPortfolioHoldingAssetInvalid: false,
      isICPortfolioHavingLiabilitiesInvalid: false,

      currencyPortfolioSettings: createDefaultPortfolioSettings(),
      settingModalVisible: false,
      settingCommoditiesModalVisible: false,
      defaultCommodity: [],
      editedDefaultCommodity: null,
      openPerformanceAfterEditCurrencySettings: false,
      portfolioCurrencySettingsSumNotValid: false,
      riskToleranceSettingsSumNotValid: false,
      portfolioCurrencySettingsSum: 0,
      fxHegdeSettings: {},
      commoditiesData: null,
      editedDefaultCommoditySettings: null,

      productSettingsVisible: false,
      productSettingsDiscriminator: null,
      editedProductSettings: null,
      productSettingsRiskValuesNotValid: false,

      productsPortfolioSettingsVisible: false,
      productPortfolioWeightsSumNotValid: false,
      productPortfolioWeightsSum: 0,
      productWeights: [],
      productsPortfolioSettingsDiscriminator: null,
      intentToChangeOverallPortfolioLimits: false,

      fxVolatility: null,

      riskFactorOptions: [
        { text: 'Static', value: 'STATIC' },
        { text: 'Implied Vol (3m)', value: 'IMPLIED_VOL' },
        { text: 'Historical Vol (3m)', value: 'HISTORICAL_VOL' },
      ]
    };
  },
  computed:{
    ...mapState('signalr', {
       connectionId: 'connectionId'
    }),
    loading() {
      return this.isLoadCurrencies || this.isLoadUser || this.recalculating;
    },
    ...mapGetters('execution', {
     
    }),
    commoditiesTabTitle() {
      return 'Commodities'
    },
    currentPortfolioName() {
      return this.portfolios.find(x => this.selectedPortfolioId == x.Id)?.Name;
    }
  },
  watch: {
    connectionId: {
      handler: function (nv, ov) {
        console.log('connectionId changed', {nv, ov});
        this.load();
      }
    }
  },
  methods: {
    ...mapActions('execution', {
    loadFxHedge: 'loadFxHedge',
    loadFxHedgeSettings: 'loadFxHedgeSettings',
    loadFxHedgeCharts: 'loadFxHedgeCharts',
    loadFxVolatility: 'loadFxVolatility'
  }),
  onICHoldingAssetTargetChanged(settings) {
    settings.HoldingAssetTarget = settings.ICHoldingAssetTarget;
  },
  onICHoldingLiabilitiesTargetChanged(settings) {
    settings.HoldingLiabilitiesTarget = settings.ICHoldingAssetTarget;
  },
  getPortfolioFormRiskToleranceModeHelp(mode) {
    if(mode === 'Simple') {
      return `This approach limits each Individual currency position to be the same as the Overall Portfolio Limits`;
    } else {
      return `This approach allows individual currency risk to be offset. This means that the Overall Portfolio limits can allow currencies that are overweight the base currency to be offset against currencies that are underweight, giving a net overweight or underweight currency position.  This overall net position will then be reduced using a machine learning process that aims to maximise the overall risk with the Overall Portfolio limit.   `;
    }
  },
  createTooltips() {
      // var tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      // console.log('tooltipTriggerList', tooltipTriggerList);
      // tooltipTriggerList.forEach(el => {
      //     ///new bootstrap.Tooltip(el);
      // });
  },
  sortTableData(source) {
    if(source.length === 0) {
      return source;
    }
    let copy = [...source];
    const f = copy.shift();
    const l = copy.pop();
    let liability = copy.filter(x => this.isLiability(x[0]));
    let assets = copy.filter(x => !this.isLiability(x[0]));
    liability = liability.sort((a, b) =>a[0].localeCompare(b[0]));
    assets = assets.sort((a, b) =>a[0].localeCompare(b[0]));
   
    return [f, ...assets, ...liability, l];
  },
  getCurrencyRiskFactor(curr) {
    return _getCurrencyRiskFactor(curr);
  },
  hideProductSettings() {
    this.productSettingsVisible = false;
    this.productSettingsDiscriminator = null;
    this.editedProductSettings = null;
    this.productSettingsRiskValuesNotValid = false;
  },
  showProductSettings(discriminator, product) {
    this.productSettingsVisible = true;
    this.productSettingsDiscriminator = discriminator;
   
    if(discriminator === 'COMMODITIES') {
      const e = this.defaultCommodity.find(x => x.product === product) || {
        product: product,
        riskTolerance: {
          min: 0,
          mid: 50,
          max: 100,
          step: 10,
          maxChanges: 100
        },
        hedgeBenchmark: 50,
        liability: false,
        weight: 0
      };
      this.editedProductSettings = JSON.parse(JSON.stringify(e));
    }
   
    this.productSettingsRiskValuesNotValid = false;
  },
  async onProductSettingsSaveToAll() {
    this.productSettingsRiskValuesNotValid = false;
    if(this.editedProductSettings) {
      if(this.editedProductSettings.riskTolerance.min > this.editedProductSettings.riskTolerance.max) {
        this.productSettingsRiskValuesNotValid = true;
        return;
      }
    }
  
    if(this.productSettingsDiscriminator === 'COMMODITIES') {
    
      const o = [...this.defaultCommodity];
      o.forEach(p => {
        p.liability = this.editedProductSettings.liability;
        p.riskTolerance = {...this.editedProductSettings.riskTolerance};
        p.hedgeBenchmark = this.editedProductSettings.hedgeBenchmark;
      });

      this.defaultCommodity = o;
    }
    this.productSettingsVisible = false;
    this.productSettingsDiscriminator = null;
    this.editedProductSettings = null;
    await this.onSave();
    this.hideProductSettings();
    this.$nextTick(() => {
      this.onRecalculate();
    });
  },
  async onProductSettingsSave()  {
    this.productSettingsRiskValuesNotValid = false;
    if(this.editedProductSettings) {
      if(this.editedProductSettings.riskTolerance.min > this.editedProductSettings.riskTolerance.max) {
        this.productSettingsRiskValuesNotValid = true;
        return;
      }
    }
  
    if(this.productSettingsDiscriminator === 'COMMODITIES') {
      const e = this.defaultCommodity.find(x => x.product === this.editedProductSettings.product);
      const eIndex = this.defaultCommodity.indexOf(e);
      const o = [...this.defaultCommodity];
      o[eIndex] = this.editedProductSettings;
      this.defaultCommodity = o;
    }
    this.productSettingsVisible = false;
    this.productSettingsDiscriminator = null;
    this.editedProductSettings = null;
    await this.onSave();
    this.hideProductSettings();
    this.$nextTick(() => {
      this.onRecalculate();
    });
  },
  async showProductsPortfolioSettings(discriminator, openPerformanceAfterSave)  {
    this.productsPortfolioSettingsVisible = true;
    this.productPortfolioWeightsSumNotValid = false;
    this.productPortfolioWeightsSum = 0;
    this.openPerformanceAfterSave = openPerformanceAfterSave;
    if(discriminator === 'COMMODITIES') {
      this.productWeights = this.defaultCommodity.map(x => ({ product: x.product, weight: x.weight}));
    }
    this.productsPortfolioSettingsDiscriminator = discriminator;
  },
  async hideProductsPortfolioSettings()  {
    this.productsPortfolioSettingsVisible = false;
    this.productPortfolioWeightsSumNotValid = false;
    this.productPortfolioWeightsSum = 0;
    this.productWeights = [];
    this.openPerformanceAfterSave = false;
  },
  async onSaveProductsPortfolioSettings()  {
    
    this.productPortfolioWeightsSum = 0;
    let sum = 0 ;
    this.productWeights.forEach(x => {
      sum += (x.weight || 0);
    });
    this.productPortfolioWeightsSum = sum;
    if(sum != 100) {
      this.productPortfolioWeightsSumNotValid = true;
      return;
    }else{
      this.productPortfolioWeightsSumNotValid = false;
    }
    if(this.productsPortfolioSettingsDiscriminator === 'COMMODITIES') {
      this.productWeights.forEach(x => {
        const o = this.defaultCommodity.find(a => a.product === x.product);
        if(o) {
          o.weight = (x.weight || 0);
        }
      });
    }
    await this.onSave();
    if(this.openPerformanceAfterSave) {
      this.hideProductsPortfolioSettings();
      this.$router.push( { name: 'fx-hedge-details', params: { target: 'Portfolio', desc: 'COMMODITIES', id: this.selectedPortfolioId} });
    } else {
      this.hideProductsPortfolioSettings();
      this.onRecalculate();
    }
   
  },
  getHeaderCurrencyVsColumnName() {
    return `Your currency ${this.defaultCurrency?.Name} vs`;
  },
  async deleteCurrentPortfolio() {
    if(this.isDeleting) return;
    this.isDeleting = true;
    await httpFxHedge.delete(`user-data/` +  this.selectedPortfolioId);
    this.selectedPortfolioId = null;
    const storageKey = this.currentUser.Id + '_portfolioId';
    localStorage.removeItem(storageKey);
    await this.load();
    this.isDeleting = false;
  },
  confirmDelete() {
    if(this.isDeleting) return;
    Swal.fire({
      title: this.$t("common.areYouSure"),
      text: this.$t("common.youCannotRevertOp"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34c38f",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: this.$t("common.yesDelete")
    }).then(result => {
      if (result.value) {
        this.deleteCurrentPortfolio();
      }
    });
  },
  isColumnHidden(columnTitle) {
    if(this.isRecommededHedgeRatio(columnTitle)) return false;
    if(columnTitle == "Drivers") return false;
    if(columnTitle == "Drivers for Hedge Ratio") return false;
    if(columnTitle == "Position") return false;
    if(columnTitle.startsWith("Your currency ")) return false;
    else return true;
      // if(columnTitle === 'FX Hedge Signal (-1 to +1)') {
      //   return true;
      // }
      // if(columnTitle === 'Trend') {
      //   return true;
      // }
      // if(this.isFXDriver(columnTitle)) {
      //   return true;
      // }
      // return false;
    },
    isFXDriver (columnTitle) {
      if(columnTitle === 'Macro' || columnTitle === 'Intrinsic' || columnTitle === 'Short term' 
      || columnTitle === 'Long term value' || columnTitle === 'Oversold/bought' || columnTitle === 'Short term value') {
        return true;
      }
      return false;
    },
    isColumnPortfolio(columnTitle) {
      if(columnTitle === 'Portfolio') {
        return true;
      }
      return false;
    },
    isRecommededHedgeRatio(columnTitle) {
      if(columnTitle === 'Hedge Ratio %') {
        return true;
      }
      return false;
    },
    
    calculatePerc(curr, val) {
      const conf = this.currencySettings.find(x => x.currency.Name === curr); 
      if(!conf) return 0;

      let max = conf.riskTolerance?.max;
      let min = conf.riskTolerance?.min;

      if(max === null || max === undefined) {
        max = 100;
      }

      if(min === null || min === undefined) {
        min = 0;
      }
     
      let range = max - min;
      let difference = val - min;
      let percentage = (difference / range) * 100;
      return parseFloat(percentage.toFixed(2));
    },
    getCurrencyHoldingAssetRiskToleranceMin() {
      return this.currencyPortfolioSettings.HoldingAssetRiskToleranceMin;
    },
    
    getCurrencyHoldingAssetRiskToleranceMax() {
      return this.currencyPortfolioSettings.HoldingAssetRiskToleranceMax ;
    },
    getCurrencyHoldingLiabilitiesRiskToleranceMin() {
      return this.currencyPortfolioSettings.HoldingLiabilitiesRiskToleranceMin;
    },
    getCurrencyHoldingLiabilitiesRiskToleranceMax() {
      return this.currencyPortfolioSettings.HoldingLiabilitiesRiskToleranceMax;
    },
    getPortfolioValue(str, n) {
      if(!str) return undefined;
      const pair = str.split(':');
      if(pair.length != 2) return undefined;
      const assetsValue = pair[0].replace('Assets', '')?.trim() || '';
      const liabilityPair = pair[1].replace('Liability', '')?.trim() || '';
      // const a = str.match(/-?\d+\.?\d*/g);
      // if(!a) return undefined;

      let numbers = [assetsValue, liabilityPair].map(num => isNaN(num) ? NaN : Number(num));
      //console.log('getPortfolioValue', {numbers, pair});
   
      
      let val = numbers[n];
      if(isNaN(val)) return undefined;

      let max = n === 0 ? this.getCurrencyHoldingAssetRiskToleranceMax() : this.getCurrencyHoldingLiabilitiesRiskToleranceMax();
      let min =  n === 0 ? this.getCurrencyHoldingAssetRiskToleranceMin() : this.getCurrencyHoldingLiabilitiesRiskToleranceMin();

      if(max === null || max === undefined) {
        max = 100;
      }

      if(min === null || min === undefined) {
        min = 0;
      }
     /*
      let range = max - min;
      let difference = val - min;
      let percentage = (difference / range) * 100;
      return parseFloat(percentage.toFixed(2));
      */

      return val;
    },
    calculatePortfolioProgressbarPerc(str, n) {
      if(!str) return undefined;
      const pair = str.split(':');
      if(pair.length != 2) return undefined;
      const assetsValue = pair[0].replace('Assets', '')?.trim() || '';
      const liabilityPair = pair[1].replace('Liability', '')?.trim() || '';
      // const a = str.match(/-?\d+\.?\d*/g);
      // if(!a) return undefined;

      let numbers = [assetsValue, liabilityPair].map(num => isNaN(num) ? NaN : Number(num));
      //console.log('getPortfolioValue', {numbers, pair});
   
      
      let val = numbers[n];
      if(isNaN(val)) return undefined;

      let max = n === 0 ? this.getCurrencyHoldingAssetRiskToleranceMax() : this.getCurrencyHoldingLiabilitiesRiskToleranceMax();
      let min =  n === 0 ? this.getCurrencyHoldingAssetRiskToleranceMin() : this.getCurrencyHoldingLiabilitiesRiskToleranceMin();

      if(max === null || max === undefined) {
        max = 100;
      }

      if(min === null || min === undefined) {
        min = 0;
      }
     
      let range = max - min;
      let difference = val - min;
      let percentage = (difference / range) * 100;
      return parseFloat(percentage.toFixed(2));
    },
    roundIfNumber(value) {
      if(value) {
        if (typeof value === 'number' || (typeof value === 'string' && !isNaN(value))) {
          let numberValue = parseFloat(value);
          return Math.round(numberValue * 100) / 100;
        }
      }
     
      return value;
    },
    showNewPortfolioDialog(templateId) {
      const currentPortfolio = this.portfolios.find(x => this.selectedPortfolioId == x.Id);
      this.newPortfolioObj = {
        Name:  templateId ? 'Portfolio - Copy' : 'Portfolio ' + moment().format('L LT'),
        ExternalSymbol: templateId ? currentPortfolio.ExternalSymbol : null,
        Description: templateId ? currentPortfolio.Description : null,
        TemplateId: templateId
      };
      this.newPortfolioDialogVisible = true;
    },
    hideNewPortfolioDialog() {
      this.newPortfolioDialogVisible = false;
      this.newPortfolioObj = null;
    },
    async submitNewPortfolio() {
      this.isSaving = true;
      const response = await httpFxHedge.post(`user-data`,  this.newPortfolioObj);
      const id = response.data.Id;
      await this.selectPortfolio(id);
      this.isSaving = false;
      this.newPortfolioDialogVisible = false;
    },
    async selectPortfolio(id) {
      if(this.selectingPortfolio) return;
      if(id === this.selectedPortfolioId) return;
      if(this.isDeleting) return;
      this.selectingPortfolio = true;
      this.selectedPortfolioId = id;
      await this.reloadPortfolio();
      this.selectingPortfolio = false;
      const storageKey = this.currentUser.Id + '_portfolioId';
      localStorage.setItem(storageKey, this.selectedPortfolioId);
    },
    cancelSettingsDialog() {
      this.settingModalVisible = false;
    },
   
    showSettingsDialog() {
      this.editedCcyExposures = null;
      this.settingModalVisible = true;
      //this.editedCcyExposures = JSON.parse(JSON.stringify(this.ccyExposures));
      this.editedCcyExposures = [...this.ccyExposures];
     // console.log('his.ccyExposures', this.ccyExposures);
    },
    showCommoditySettingsDialog() {
      this.settingCommoditiesModalVisible = true;
      this.editedDefaultCommodity = this.commodities.filter(x => this.defaultCommodity.find(a => x.Name === a.product));
    },
    hideCommoditySettingsDialog() {
      this.settingCommoditiesModalVisible = false;
      this.editedDefaultCommodity = null;
    },
    onSaveComodities() {
      let o = [];
      this.editedDefaultCommodity.forEach(a => {
        const e = this.defaultCommodity.find(x => x.product === a.Name);
        if(e) {
          o.push(e);
        }else {
          o.push({
            product: a.Name,
            riskTolerance: {
              min: 0,
              mid: 50,
              max: 100,
              step: 10,
              maxChanges: 100
            },
            hedgeBenchmark: 50,
            liability: false,
            weight: 0
          });
        }
      });
      this.defaultCommodity = o;
      this.hideCommoditySettingsDialog();
      this.onSave();
      this.onRecalculate(); 
    },
    async onDebSaveToAll() {
      window.setTimeout(() =>{
        this.onSaveToAll();
      }, 100);
    },
    async onSaveToAll() {
      this.riskToleranceSettingsSumNotValid = false;
        if(this.editedCurrency) {
          if(this.editedCurrency.riskTolerance.min > this.editedCurrency.riskTolerance.max) {
            this.riskToleranceSettingsSumNotValid = true;
            return;
          }
        }

      this.currencySettings.forEach(a => {
        a.hedgeBenchmark = this.editedCurrency.hedgeBenchmark;
        a.liability = this.editedCurrency.liability;
        a.riskTolerance = {
          min: this.editedCurrency.riskTolerance.min,
          mid: this.editedCurrency.riskTolerance.mid,
          max: this.editedCurrency.riskTolerance.max, 
          step: this.editedCurrency.riskTolerance.step,
          maxChanges: this.editedCurrency.riskTolerance.maxChanges,
          weight: this.editedCurrency.weight,
          position: this.editedCurrency.position,
          overrideLatest: this.editedCurrency.overrideLatest,
          riskFactor: this.editedCurrency.riskFactor,
        };
      });
      this.onSave();
    },
    recalculateWeights() {
      let positionSum = 0 ;
      // let weightSum = 0 ;
      if(this.currencySettings) {
        this.currencySettings.forEach(x => {
          if(isNumber(x.position)) {
            positionSum += (x.position < 0 ? 0 : (x.position || 0));
          }
        });

        this.currencySettings.forEach(x => {
          if(positionSum > 0) {
            const w = (x.position / positionSum) * 100;
            x.weight = parseFloat(w.toFixed(2));
            //weightSum += x.weight;
          } else {
            x.weight = 0;
          }
        });
      }

      this.portfolioCurrencySettingsSumNotValid = false;

      if(this.fxVolatility) {
        this.currencySettings.forEach(x => {
          const currency = x.currency.Name;
          
          if(!x.overrideLatest) {
            if(this.currencyPortfolioSettings.RiskFactor === 'IMPLIED_VOL') {
              x.riskFactor = this.fxVolatility.ImpliedVolatility[currency] === null 
              || this.fxVolatility.ImpliedVolatility[currency] === undefined 
              || this.fxVolatility.ImpliedVolatility[currency] === 'NaN' ? 7
              : parseFloat(((this.fxVolatility.ImpliedVolatility[currency] || 0) * 100).toFixed(2));
            } else if(this.currencyPortfolioSettings.RiskFactor === 'HISTORICAL_VOL') {
              x.riskFactor = this.fxVolatility.HistoricalVolatility[currency] === null 
              || this.fxVolatility.HistoricalVolatility[currency] === undefined 
              || this.fxVolatility.HistoricalVolatility[currency] === 'NaN' ? 7
              : parseFloat(((this.fxVolatility.HistoricalVolatility[currency] || 0) * 100).toFixed(2));
            } else if(this.currencyPortfolioSettings.RiskFactor === 'STATIC') {
              x.riskFactor = this.fxVolatility.Static[currency] === null 
              || this.fxVolatility.Static[currency] === undefined 
              || this.fxVolatility.Static[currency] === 'NaN' ? 0.07
              : parseFloat(((this.fxVolatility.Static[currency] || 0) * 100).toFixed(2));
            }
          }
        });
        
      }
    },
    portfolioSettingsRiskFactorValueChanged() {
      // let riskFactorSum = 0;
      // this.currencySettings.forEach(x => {
      //   if(isNumber(x.riskFactor)) {
      //     riskFactorSum += x.riskFactor;
      //   }
      // });
    


      if(this.isAllAssets()) {
        this.currencyPortfolioSettings.HoldingLiabilitiesRiskToleranceMin = -70;
        this.currencyPortfolioSettings.HoldingLiabilitiesRiskToleranceMax = -30;
        this.currencyPortfolioSettings.HoldingLiabilitiesTarget = -50;

        this.currencyPortfolioSettings.ICHoldingLiabilitiesRiskToleranceMin = -100;
        this.currencyPortfolioSettings.ICHoldingLiabilitiesRiskToleranceMax = 0;
        this.currencyPortfolioSettings.ICHoldingLiabilitiesTarget = -50;
      }

      if(this.isAllLiability()) {
        this.currencyPortfolioSettings.HoldingAssetRiskToleranceMin = 30;
        this.currencyPortfolioSettings.HoldingAssetRiskToleranceMax = 70;
        this.currencyPortfolioSettings.HoldingAssetTarget = 50;

        this.currencyPortfolioSettings.ICHoldingAssetRiskToleranceMin = 0;
        this.currencyPortfolioSettings.ICHoldingAssetRiskToleranceMax = 100;
        this.currencyPortfolioSettings.ICHoldingAssetTarget = 50;
      }

      this.recalculateWeights();
    },
    portfolioSettingsRiskFactorTypeChanged() {
      
      this.recalculateWeights();
    },
    isAllAssets() {
      let res = true;
      this.currencySettings.forEach(x => {
        if(x.liability) {
          res = false;
        }
      });
      return res;
    },
    isAllLiability() {
      let res = true;
      this.currencySettings.forEach(x => {
        if(!x.liability) {
          res = false;
        }
      });
      return res;
    },
    /*
    onOverallPortfolioLimits: debounce(function () {
      this.onSave(true);
    }, 1000),
    */
    async onOverallPortfolioLimits(currencyPortfolioSettings) {
      if(currencyPortfolioSettings.OverallPortfolioLimits) {
        this.intentToChangeOverallPortfolioLimits = true;

        this.currencyPortfolioSettings.HoldingLiabilitiesRiskToleranceMin = -70;
        this.currencyPortfolioSettings.HoldingLiabilitiesRiskToleranceMax = -30;
        this.currencyPortfolioSettings.HoldingLiabilitiesTarget = -50;

        this.currencyPortfolioSettings.ICHoldingLiabilitiesRiskToleranceMin = -100;
        this.currencyPortfolioSettings.ICHoldingLiabilitiesRiskToleranceMax = 0;
        this.currencyPortfolioSettings.ICHoldingLiabilitiesTarget = -50;

        this.currencyPortfolioSettings.HoldingAssetRiskToleranceMin = 30;
        this.currencyPortfolioSettings.HoldingAssetRiskToleranceMax = 70;
        this.currencyPortfolioSettings.HoldingAssetTarget = 50;
        
        this.currencyPortfolioSettings.ICHoldingAssetRiskToleranceMin = 0;
        this.currencyPortfolioSettings.ICHoldingAssetRiskToleranceMax = 100;
        this.currencyPortfolioSettings.ICHoldingAssetTarget = 50;

        this.editCurrencySettings('Portfolio')
      } else {
       
        await this.onSave();
        //this.onRecalculate();
      }
    },
    async onDebSave() {
      window.setTimeout(() =>{
        this.onSave();
      }, 100);
    },
    async onSettingsSave() {
      this.editedCurrency = null;
      //console.log('onSettingsSave', {old: this.ccyExposures, new: this.editedCcyExposures });
      this.ccyExposures = this.editedCcyExposures;
      this.currencyPortfolioSettings = createDefaultPortfolioSettings();
      await this.onSave();
     
    },
    
    async onSave(suppressReload) {
      
      this.portfolioCurrencySettingsSumNotValid = false;
      this.riskToleranceSettingsSumNotValid = false;
      this.isPortfolioHavingLiabilitiesInvalid = false;
      this.isPortfolioHoldingAssetInvalid = false;
      this.isICPortfolioHavingLiabilitiesInvalid = false;
      this.isICPortfolioHoldingAssetInvalid = false;
      
      if(this.editedCurrency?.Name === 'Portfolio') {
      
        console.error('onSave Portfolio')
        this.currencySettings.forEach(x => {
         if(!(x.position > 0)) {
          this.portfolioCurrencySettingsSumNotValid = true;
         }
        });

        if(this.portfolioCurrencySettingsSumNotValid){
          return;
        }
        
       

      
        if(!this.isAllAssets()) {
          if(this.currencyPortfolioSettings.HoldingLiabilitiesRiskToleranceMin > this.currencyPortfolioSettings.HoldingLiabilitiesTarget
            || this.currencyPortfolioSettings.HoldingLiabilitiesRiskToleranceMin > this.currencyPortfolioSettings.HoldingLiabilitiesRiskToleranceMax) {
            this.isPortfolioHavingLiabilitiesInvalid = true;
          }
          if(this.currencyPortfolioSettings.HoldingLiabilitiesTarget > this.currencyPortfolioSettings.HoldingLiabilitiesRiskToleranceMax) {
            this.isPortfolioHavingLiabilitiesInvalid = true;
          }

          if(this.currencyPortfolioSettings.OverallPortfolioLimits) {
            if(this.currencyPortfolioSettings.ICHoldingLiabilitiesRiskToleranceMin > this.currencyPortfolioSettings.ICHoldingLiabilitiesTarget
              || this.currencyPortfolioSettings.ICHoldingLiabilitiesRiskToleranceMin > this.currencyPortfolioSettings.ICHoldingLiabilitiesRiskToleranceMax) {
              this.isICPortfolioHavingLiabilitiesInvalid = true;
            }
            if(this.currencyPortfolioSettings.ICHoldingLiabilitiesTarget > this.currencyPortfolioSettings.ICHoldingLiabilitiesRiskToleranceMax) {
              this.isICPortfolioHavingLiabilitiesInvalid = true;
            }
          }
        }

        if(!this.isAllLiability()) {
          if(this.currencyPortfolioSettings.HoldingAssetRiskToleranceMin > this.currencyPortfolioSettings.HoldingAssetTarget
            || this.currencyPortfolioSettings.HoldingAssetRiskToleranceMin > this.currencyPortfolioSettings.HoldingAssetRiskToleranceMax) {
            this.isPortfolioHoldingAssetInvalid = true;
          }

          if(this.currencyPortfolioSettings.HoldingAssetTarget > this.currencyPortfolioSettings.HoldingAssetRiskToleranceMax) {
            this.isPortfolioHoldingAssetInvalid = true;
          }

          if(this.currencyPortfolioSettings.ICHoldingAssetRiskToleranceMin > this.currencyPortfolioSettings.ICHoldingAssetTarget
            || this.currencyPortfolioSettings.ICHoldingAssetRiskToleranceMin > this.currencyPortfolioSettings.ICHoldingAssetRiskToleranceMax) {
            this.isICPortfolioHoldingAssetInvalid = true;
          }
          if(this.currencyPortfolioSettings.ICHoldingAssetTarget > this.currencyPortfolioSettings.ICHoldingAssetRiskToleranceMax) {
            this.isICPortfolioHoldingAssetInvalid = true;
          }
        }

        if(this.isPortfolioHoldingAssetInvalid || this.isPortfolioHavingLiabilitiesInvalid || this.isICPortfolioHoldingAssetInvalid || this.isICPortfolioHavingLiabilitiesInvalid) {
          console.error('cannot close dialog. errors', {
            isPortfolioHoldingAssetInvalid: this.isPortfolioHoldingAssetInvalid,
            isPortfolioHavingLiabilitiesInvalid: this.isPortfolioHavingLiabilitiesInvalid,
            isICPortfolioHoldingAssetInvalid: this.isICPortfolioHoldingAssetInvalid,
            isICPortfolioHavingLiabilitiesInvalid: this.isICPortfolioHavingLiabilitiesInvalid
            
          })
          return;
        }

        if(this.currencyPortfolioSettings.RiskPortfolioFormRiskToleranceMode === 'Simple') {
          if(this.currencyPortfolioSettings.OverallPortfolioLimits) {
            this.currencySettings.forEach(a => {
              if(a.liability) {
                a.riskTolerance = {
                  min: this.currencyPortfolioSettings.HoldingLiabilitiesRiskToleranceMin,
                  mid: this.currencyPortfolioSettings.HoldingLiabilitiesTarget,
                  max: this.currencyPortfolioSettings.HoldingLiabilitiesRiskToleranceMax
                };
              } else {
                a.riskTolerance = {
                  min: this.currencyPortfolioSettings.HoldingAssetRiskToleranceMin,
                  mid: this.currencyPortfolioSettings.HoldingAssetTarget,
                  max: this.currencyPortfolioSettings.HoldingAssetRiskToleranceMax
                };
              }
            });
          }
          
        } else {
          if(this.currencyPortfolioSettings.OverallPortfolioLimits) {
            this.currencySettings.forEach(a => {
              if(a.liability) {
                a.riskTolerance = {
                  min: this.currencyPortfolioSettings.ICHoldingLiabilitiesRiskToleranceMin,
                  mid: this.currencyPortfolioSettings.ICHoldingLiabilitiesTarget,
                  max: this.currencyPortfolioSettings.ICHoldingLiabilitiesRiskToleranceMax
                };
              } else {
                a.riskTolerance = {
                  min: this.currencyPortfolioSettings.ICHoldingAssetRiskToleranceMin,
                  mid: this.currencyPortfolioSettings.ICHoldingAssetTarget,
                  max: this.currencyPortfolioSettings.ICHoldingAssetRiskToleranceMax
                };
              }
            
            });
          }
        }

      } else {
        this.riskToleranceSettingsSumNotValid = false;
        if(this.editedCurrency) {
          if(this.editedCurrency.riskTolerance.min > this.editedCurrency.riskTolerance.max) {
            this.riskToleranceSettingsSumNotValid = true;
            return;
          }
        }
      }
      if(this.editedCurrency && this.editedCurrency?.Name !== 'Portfolio') {
        const conf = this.currencySettings.find(x => x.currency.Name === this.editedCurrency.currency.Name); 
        const confIndex = this.currencySettings.indexOf(conf);
        const o = [...this.currencySettings];
        o[confIndex] = this.editedCurrency;
        this.currencySettings = o;
        this.recalculateWeights();
      }
      
      this.isSaving = true;
      try {
      
        const dto = {
          Name: this.portfolioName,
          ExternalSymbol: this.portfolioExternalSymbol,
          Description: this.portfolioDescription,
          KeyCcyExposures: this.ccyExposures.map(x => x.Name),
          BaseCurrency: this.defaultCurrency?.Name || null,
          CurrencyPortfolioSettings: this.currencyPortfolioSettings,
          CurrencyConfigs: this.currencySettings.map(x =>({
            Currency: x.currency.Name,
            Benchmark: x.hedgeBenchmark,
            Liability: x.liability || false,
            RiskToleranceMin: x.riskTolerance.min,
            RiskToleranceMid: x.riskTolerance.mid,
            RiskToleranceMax: x.riskTolerance.max,
            RiskToleranceStep: (x.riskTolerance.step === null || x.riskTolerance.step === undefined) ? 10 : x.riskTolerance.step,
            RiskToleranceMaxChanges: (x.riskTolerance.maxChanges == null || x.riskTolerance.maxChanges == undefined || x.riskTolerance.maxChanges === '') ? 100 : x.riskTolerance.maxChanges,
            Weight: x.weight,
            Position: x.position,
            OverrideLatest: x.overrideLatest,
            RiskFactor: (x.riskFactor == null || x.riskFactor == undefined) ? _getCurrencyRiskFactor(x.currency.Name) : x.riskFactor,
          })),
          AdditionalData: 
          [
            { 
              Discriminator: 'COMMODITIES',
              BaseCurrency: 'COMMODITIES',
              CurrencyConfigs: this.defaultCommodity.map(x => ({
                Discriminator: 'COMMODITIES',
                Currency: x.product,
                Benchmark: x.hedgeBenchmark,
                Liability: x.liability || false,
                RiskToleranceMin: x.riskTolerance?.min,
                RiskToleranceMid: x.riskTolerance?.mid,
                RiskToleranceMax: x.riskTolerance?.max,
                RiskToleranceStep: (x.riskTolerance.step === null || x.riskTolerance.step === undefined) ? 10 : x.riskTolerance.step,
                RiskToleranceMaxChanges: (x.riskTolerance.maxChanges == null || x.riskTolerance.maxChanges == undefined || x.riskTolerance.maxChanges === '') ? 100 : x.riskTolerance.maxChanges,
                Weight: x.weight
              }))
            }
          ]
        };
        const originalPortfolio = this.portfolios.find(x => x.Id === this.selectedPortfolioId);
        const originalPortfolioIndex = this.portfolios.indexOf(originalPortfolio);
        dto.Id = originalPortfolio.Id;
        const payload = [...this.portfolios];
        payload[originalPortfolioIndex] = dto;
        await httpFxHedge.put(`user-data`, payload);
        this.portfolios = payload;
        if(this.openPerformanceAfterEditCurrencySettings) {
          this.$router.push( { name: 'fx-hedge-details', params: { target: 'Portfolio', desc: 'CURRENCIES', id: this.selectedPortfolioId} });
        }
      } finally {

        if(this.intentToChangeOverallPortfolioLimits) {
          this.intentToChangeOverallPortfolioLimits = false;
        }
        this.showEditCurrencySettings = false;
        this.showEditPortfolioSettings = false;
        this.isSaving = false;
        this.settingModalVisible = false;
        this.openPerformanceAfterEditCurrencySettings = false;
        this.portfolioCurrencySettingsSumNotValid = false;
      }

      if(suppressReload !== true) {
        await this.loadFxHedgeUserData();
       
        await this.onRecalculate();
        await this.loadCharts();
      }
    
    },
    isLiability(curr) {
      const conf = this.currencySettings.find(x => x.currency.Name === curr); 
      return conf?.liability || false;
    },
    getHedgeRiskMin(curr) {
      const conf = this.currencySettings.find(x => x.currency.Name === curr); 
      const v =conf?.riskTolerance?.min;
      return v == null || v === undefined ? '' : v;
    },
    getHedgeRiskMax(curr) {
      const conf = this.currencySettings.find(x => x.currency.Name === curr); 
      const v =conf?.riskTolerance?.max;
      return v == null || v === undefined ? '' : v;
    },
    getCommodityHedgeRiskMin(product) {
      const conf = this.defaultCommodity.find(x => x.product === product); 
      const v =conf?.riskTolerance?.min;
      return v == null || v === undefined ? '' : v;
    },
    getCommodityHedgeRiskMax(product) {
      const conf = this.defaultCommodity.find(x => x.product === product); 
      const v =conf?.riskTolerance?.max;
      return v == null || v === undefined ? '' : v;
    },
    editCurrencySettings(curr) {
      this.editedCcyExposures = null;
      this.riskToleranceSettingsSumNotValid = false;
      this.portfolioCurrencySettingsSumNotValid = false;

      this.portfolioSettings = null;
      this.editedCurrency = null;
     
      if(curr === 'Portfolio') {
        this.isPortfolioHoldingAssetInvalid = false;
        this.isPortfolioHavingLiabilitiesInvalid = false;
        this.showEditPortfolioSettings = true;
        this.editedCurrency = {
          Name: 'Portfolio',
          riskTolerance: {
            min: 0,
            mid: 50,
            max: 100,
            step: 10,
            maxChanges: 100
          },
        }
        this.originalCurrencySettings = JSON.stringify(this.currencySettings);
        this.originalCurrencyPortfolioSettings = JSON.stringify(this.currencyPortfolioSettings);
        
      } else {
        this.showEditCurrencySettings = true;
        const conf = this.currencySettings.find(x => x.currency.Name === curr); 
        this.editedCurrency = JSON.parse(JSON.stringify(conf));
        if(!this.editedCurrency.riskTolerance.maxChanges) {
          this.editedCurrency.riskTolerance.maxChanges = 100;
        }
      }
     
    },
    onCancelEditCurrencySettings() {
      this.showEditCurrencySettings = false;
    },
    async loadCharts() {
      this.chartsLoading = true;
      try {
        const payload = {
          userId: this.currentUser.Id,
          roles: [...this.currentUser.Roles || []],
          currency: this.chartsCurrency?.Name,
        };

        const response = await this.loadFxHedgeCharts(payload);
      
        this.chartsData = response;
        this.chartOptions = [];
        response.ChartData.forEach(chartData => {
          let seriesOptions = [
            {
              name: chartData.Currency || 'Performance',
              label: chartData.Currency || 'Performance',
              data: (chartData.Points || []).map(x => [x.TimeStamp, x.Value]),
            // color: '#3366CC',
              lineWidth: 2
            }
          ];
          const chartOptions = {
              credits:{
                enabled: false
              },
              legend:{
                enabled: true
              },
              exporting: {
                buttons: {
                  
                }
              },
              rangeSelector: {
                
              },
              xAxis: {
                type: 'datetime',
              
                labels: {
                    
                },
              
              },
              yAxis: {
                type: null,
                labels: {
                  formatter: function () {
                    return (this.value > 0 ? '' : '') + this.value;
                  }
                },
                plotLines: [{
                  value: 0,
                    width: 2,
                    color: 'silver'
                }]
              },
              plotOptions: {
                series: {
                  showInNavigator: true
                }
              },
              colors: colors,
              series: seriesOptions
          };
          this.chartOptions = [...this.chartOptions, chartOptions];
        });

        console.log('loadCharts', {response});
      } finally {
        this.chartsLoading = false;
      }
     
    },
    async _loadFxTabData() {
      const fxResponse = await this.loadFxHedge(
          {
            userId: this.currentUser.Id,
            roles: [...this.currentUser.Roles || []],
            defaultCurrency:this.defaultCurrency?.Name || null,
            ccyExposures: this.ccyExposures.map(x => x.Name),
            currencySettings: this.currencySettings.map(c => ({
              ...c,
              currency: c.currency.Name,

            })),
            currencyPortfolioSettings: camelizeKeys(this.currencyPortfolioSettings),
            riskFactorMethod: this.currencyPortfolioSettings.RiskFactor
          }
        );
        this.fxHedgeData = fxResponse;
    },
    async _loadCommoditiesTabData() {
      // const response = await this.loadFxHedge(
      //     {
      //       userId: this.currentUser.Id,
      //       roles: [...this.currentUser.Roles || []],
      //       defaultCurrency: '',
      //       products: this.defaultCommodity.map(x => x.product),
      //       currencySettings: this.defaultCommodity.map(x => ({
      //           currency: x.product,
      //           hedgeBenchmark: this.defaultCommoditySettings?.hedgeBenchmark || 50,
      //           liability: x.liability || false,
      //           riskTolerance: {
      //             min: x.riskTolerance.min,
      //             mid: x.riskTolerance.mid,
      //             max: x.riskTolerance.max,
      //             step: (x.riskTolerance.step === null || x.riskTolerance.step === undefined) ? 10 : x.riskTolerance.step,
      //             maxChanges: (x.riskTolerance.maxChanges == null || x.riskTolerance.maxChanges == undefined || x.riskTolerance.maxChanges == '') ? 100 : x.riskTolerance.maxChanges,
      //           },
      //           weight: x.weight || 0,
      //           //riskFactor: (x.RiskFactor == null || x.RiskFactor == undefined) ? _getCurrencyRiskFactor(curr) : x.RiskFactor,
      //         }))
      //     }
      //   );
      //   this.commoditiesData = response;
    },
    async onRecalculate() {
      this.recalculating = true;
      try {
        this.recalculateWeights();
        await this._loadFxTabData();
        await this._loadCommoditiesTabData();
      } catch(e) {
        console.error(e);
      } finally {
        this.recalculating = false;
      }
    },
    onLiabilityChanged(obj) {
      
      if(obj.liability) {
       
        obj.riskTolerance.max = 0;
        obj.riskTolerance.min = -100;
        obj.riskTolerance.mid = -50;
        obj.hedgeBenchmark = -50;
      } else {
        obj.riskTolerance.max = 100;
        obj.riskTolerance.min = 0;
        obj.riskTolerance.mid = 50;
        obj.hedgeBenchmark = 50;
      }
    },
    splitFxDriverString(input) {
      return input.split(';').map(s => s.trim()).filter(Boolean);
    },
    splitFxDriverToWords(input) {
      const words = [];
      let currentWord = '';
      let inBrackets = 0;

      for (const char of input) {
        if (char === '[') {
          inBrackets++;
        } else if (char === ']') {
          inBrackets--;
        }

        // If inBrackets is zero, we are not within brackets.
        if (char === ' ' && inBrackets === 0) {
          // If currentWord is not empty, push to words and reset currentWord
          if (currentWord.trim()) {
            words.push(currentWord.trim());
            currentWord = '';
          }
        } else {
          // Add the char to the current word.
          currentWord += char;
        }
      }

      // After the loop ends, if there's a remaining word, push it into words.
      if (currentWord.trim()) {
        words.push(currentWord.trim());
      }

      return words.filter(Boolean); // Filter out any empty strings just in case
    },
    removeSquareBracketsContent(str) {
        return str.replace(/\[.*?\]/g, '').trim();
    },
    extractSquareBracketsContent(str) {
      const matches = str.match(/\[(.*?)\]/g);
      const res = matches ? matches.map(match => match.slice(1, -1)).join(' ') : '';
      return res;
    },
    updateCurrencySettings() {
      this.currencySettings = [];
      this.ccyExposures.forEach(curr => {
        const conf = this.defaultCurrencyConfigs.find(x => x.Currency === curr.Name);
        const s = {
          currency: curr,
          collapsed: true,
          hedgeBenchmark: conf?.Benchmark || 50,
          liability: conf?.Liability || false,
          riskTolerance: {
            min: (conf?.RiskToleranceMin === null || conf?.RiskToleranceMin === undefined) ? 0 :  conf?.RiskToleranceMin,
            mid: (conf?.RiskToleranceMid === null || conf?.RiskToleranceMid === undefined) ? 50 :  conf?.RiskToleranceMid,
            max: (conf?.RiskToleranceMax === null || conf?.RiskToleranceMax === undefined) ? 100 :  conf?.RiskToleranceMax,
            step: (conf?.RiskToleranceStep === null || conf?.RiskToleranceStep === undefined) ? 10 : conf?.RiskToleranceStep,
            maxChanges: (conf?.RiskToleranceMaxChanges === null || conf?.RiskToleranceMaxChanges === undefined) ? 100 :  conf?.RiskToleranceMaxChanges,
          },
          weight: (conf?.Weight == null || conf?.Weight == undefined) ? 0 : conf?.Weight,
          position: (conf?.Position == null || conf?.Position == undefined) ? 0 : conf?.Position,
          overrideLatest: (conf?.OverrideLatest == null || conf?.OverrideLatest == undefined) ? false : conf?.OverrideLatest,
          riskFactor: (conf?.RiskFactor == null || conf?.RiskFactor == undefined) ? _getCurrencyRiskFactor(curr.Name) : conf?.RiskFactor,
        };
        this.currencySettings.push(s);
      });
      this.recalculateWeights();
    },
    async load() {
      if(!this.connectionId) return;
      await this.loadCurrencies();
      await this.loadUser();
      await this.loadFxHedgeUserData();
     
      await this.onRecalculate();
      await this.loadCharts();
    },
    async reloadPortfolio() {
      if(!this.connectionId) return;
      await this.loadCurrencies();
      await this.loadFxHedgeUserData();
      await this.onRecalculate();
    },
    async loadCurrencies()  {
      this.isLoadCurrencies = true;
      try {
        const resp = await http.get(`data/Currency/objects`, {
          params: {
           $orderby: 'Name asc'
          }
        });
        this.currencies = resp.data.Items;//.filter(x => x.Name !== 'CNY');
      }
      finally {
        this.isLoadCurrencies = false;
      }
    },
    hideEditPortfolioSettings() {
     
      this.portfolioCurrencySettingsSumNotValid = false;
      this.showEditPortfolioSettings = false;
      this.isPortfolioHoldingAssetInvalid = false;
      this.isPortfolioHavingLiabilitiesInvalid = false;
      this.openPerformanceAfterEditCurrencySettings= false;

      if(this.originalCurrencySettings) {
        this.currencySettings = JSON.parse(this.originalCurrencySettings);
        this.recalculateWeights();
      }

      if(this.originalCurrencyPortfolioSettings) {
        this.currencyPortfolioSettings = JSON.parse(this.originalCurrencyPortfolioSettings);
      }

      if(this.intentToChangeOverallPortfolioLimits) {
        this.intentToChangeOverallPortfolioLimits = false;
        this.currencyPortfolioSettings.OverallPortfolioLimits = false;
      }
    },
    onPortfolioPerformance(curr) {
      this.portfolioCurrencySettingsSumNotValid = false;
      this.openPerformanceAfterEditCurrencySettings= true;
      this.editCurrencySettings(curr);
    },
    updateRiskToleranceValues(pr, propName) {
      if(propName === 'min') {
        if(pr.min > pr.mid) {
         pr.mid = pr.min;
        }
      
        if(pr.mid > pr.max) {
          pr.max = pr.mid;
        }
      } else if(propName === 'mid') {
        if(pr.min > pr.mid) {
         pr.min = pr.mid;
        }
      
        if(pr.mid > pr.max) {
          pr.max = pr.mid;
        }
      } else if(propName === 'max') {
        if(pr.mid > pr.max) {
          pr.mid = pr.max;
        }

        if(pr.min > pr.mid) {
         pr.min = pr.mid;
        }
      }  
    },
    riskToleranceValidateInput(pr, propName) {
      this.validateInput(pr, propName, -200, 200);
      //this.updateRiskToleranceValues(pr, propName);
    },
    validateInput(pr, propName, min, max) {
    
      if(pr[propName] === null || pr[propName] === undefined) {
        pr[propName] = '';
      }
    
      if(pr[propName] && pr[propName].toString().trim() !== '-') {
        pr[propName] = parseInt(pr[propName]);
      }
      
      if(pr[propName] === null || pr[propName] === undefined) {
        pr[propName] = null;
      }
      if(pr[propName]) {
        if (pr[propName] < min) {
          pr[propName] = min;
        } else if (pr[propName] > max) {
          pr[propName] = max;
        }
      }

      this.recalculateWeights();
    },
    riskToleranceClampValue(pr, propName) {
      this.clampValue(pr, propName, -200, 200);
      //this.updateRiskToleranceValues(pr, propName);
    },
    clampValue(pr, propName, min, max) {
      if(pr[propName] !== null && pr[propName] !== undefined) {
        //pr[propName] = parseInt(pr[propName], 10) || 0; 
        if (pr[propName] < min) {
          pr[propName] = min;
        } else if (pr[propName] > max) {
          pr[propName] = max;
        }
      } else {
        pr[propName] = min;
      }

      this.recalculateWeights();
    },
    setPortfolioRiskRoleranceMode(v) {
      if(v !== this.currencyPortfolioSettings.RiskPortfolioFormRiskToleranceMode) {
        this.currencyPortfolioSettings.RiskPortfolioFormRiskToleranceMode = v;
        if(v === 'WithRiskOffset') {
          this.currencyPortfolioSettings.ICHoldingLiabilitiesRiskToleranceMin = -100;
          this.currencyPortfolioSettings.ICHoldingLiabilitiesRiskToleranceMax = 0;
          this.currencyPortfolioSettings.ICHoldingLiabilitiesTarget = -50;
          this.currencyPortfolioSettings.ICHoldingAssetRiskToleranceMin = 0;
          this.currencyPortfolioSettings.ICHoldingAssetRiskToleranceMax = 100;
          this.currencyPortfolioSettings.ICHoldingAssetTarget = 50;

          this.onICHoldingAssetTargetChanged(this.currencyPortfolioSettings);
          this.onICHoldingLiabilitiesTargetChanged(this.currencyPortfolioSettings);
        }
      }
    },
    async loadFxHedgeUserData() {
      const fxHegdeSettingsPayload = {
        userId: this.currentUser.Id,
        roles: [...this.currentUser.Roles || []]
      };
      this.fxHegdeSettings = await this.loadFxHedgeSettings(fxHegdeSettingsPayload); 
    
      const resp = await httpFxHedge.get(`user-data`);
      this.portfolios = resp.data;
      if(!this.selectedPortfolioId) {
        const storageKey = this.currentUser.Id + '_portfolioId';
        const storedPortfolioId = localStorage.getItem(storageKey);
        if( this.portfolios.find(x => x.Id === storedPortfolioId)) {
          this.selectedPortfolioId = storedPortfolioId;
        } else {
          this.selectedPortfolioId = this.portfolios[0].Id;
        }
      }
      const currentPorfolio = this.portfolios.find(x => x.Id === this.selectedPortfolioId);
      this.portfolioName = currentPorfolio.Name;
      this.portfolioExternalSymbol = currentPorfolio.ExternalSymbol;
      this.portfolioDescription = currentPorfolio.Description;
      var fxHedgeSettingsKeys = Object.keys(this.fxHegdeSettings.BaseCurrenciesExposure || {});
      this.currencies =  fxHedgeSettingsKeys.filter(x => x.startsWith('FX_'))
        .map(x => ({ 
            Name: x.substring(3), 
            DisplayName: x.substring(3), 
            Id:  x.substring(3),
            CurrencyExposures:  this.fxHegdeSettings.BaseCurrenciesExposure[x].map(a => ({
              Name: a, 
              DisplayName: a, 
              Id: a,
            }))
          })
        ); //this.currencies.filter(x => this.fxHegdeSettings.BaseCurrenciesExposure[x.Name]);

      this.commodities =  fxHedgeSettingsKeys.filter(x => x.startsWith('CO_'))
        .map(x => ({ 
            Name: x.substring(3), 
            DisplayName: x.substring(3), 
            Id:  x.substring(3)
          })
        );

      const usd = this.currencies.find(x => x.Name === 'USD');
      const { BaseCurrency, KeyCcyExposures, CurrencyConfigs, AdditionalData, CurrencyPortfolioSettings } = currentPorfolio;
      this.defaultCurrencyConfigs = CurrencyConfigs;
      this.additionalUserHedgeData = AdditionalData;
      this.defaultCurrency = this.currencies.find(x => x.Name === BaseCurrency) || usd;
      this.chartsCurrency = this.defaultCurrency;
      this.currencyPortfolioSettings = {...createDefaultPortfolioSettings(), ...CurrencyPortfolioSettings};
      if(!this.currencyPortfolioSettings.RiskPortfolioFormRiskToleranceMode) {
        this.currencyPortfolioSettings.RiskPortfolioFormRiskToleranceMode = 'WithRiskOffset';
      }
      if(this.currencyPortfolioSettings.ICHoldingLiabilitiesRiskToleranceMax === 0 && this.currencyPortfolioSettings.ICHoldingLiabilitiesRiskToleranceMin === 0 && this.currencyPortfolioSettings.ICHoldingLiabilitiesTarget === 0) {
        this.currencyPortfolioSettings.ICHoldingLiabilitiesRiskToleranceMax = 0;
        this.currencyPortfolioSettings.ICHoldingLiabilitiesRiskToleranceMin = -100;
        this.currencyPortfolioSettings.ICHoldingLiabilitiesTarget = -50;
      }

      if(this.currencyPortfolioSettings.ICHoldingAssetRiskToleranceMax === 0 && this.currencyPortfolioSettings.ICHoldingAssetTarget === 0 && this.currencyPortfolioSettings.ICHoldingAssetRiskToleranceMin === 0) {
        this.currencyPortfolioSettings.ICHoldingAssetRiskToleranceMax = 100;
        this.currencyPortfolioSettings.ICHoldingAssetTarget = 50;
        this.currencyPortfolioSettings.ICHoldingAssetRiskToleranceMin = 0;
      }
      const additionalData = this.additionalUserHedgeData.find(x => x.Discriminator === 'COMMODITIES');
      this.defaultCommodity = additionalData ? additionalData.CurrencyConfigs.map(x => ({
        product: x.Currency,
        riskTolerance: {
          min: x.RiskToleranceMin,
          mid: x.RiskToleranceMid,
          max: x.RiskToleranceMax,
          step: (x.RiskToleranceStep === null || x.RiskToleranceStep === undefined) ? 10 : x.RiskToleranceStep,
          maxChanges: (x.RiskToleranceMaxChanges == null || x.RiskToleranceMaxChanges == undefined || x.RiskToleranceMaxChanges === '') ? 100 :  x.RiskToleranceMaxChanges,
        },
        hedgeBenchmark: x.Benchmark,
        liability: x.Liability,
        weight: x.Weight
      })) : [];
      
      if(this.defaultCommodity.length === 0) {
        this.defaultCommodity.push({
        product: 'CO',
        riskTolerance: {
          min: 0,
          mid: 50,
          max: 100,
          step: 10,
          maxChanges: 100
        },
        hedgeBenchmark: 50,
        liability: false,
        weight: 0
      });
      }
     
      const ccyExposures = []; 
      if(KeyCcyExposures) {
        KeyCcyExposures.forEach(currency => {
          const c = this.currencies.find(x => x.Name === currency);
          if(c) {
            ccyExposures.push(c);
          }
        })
      }
      if(ccyExposures.length === 0) {
        ccyExposures.push(this.defaultCurrency);
      }
      this.ccyExposures = ccyExposures;      
      this.updateCurrencySettings();    

      const fxVolatilityPayload = {
        userId: this.currentUser.Id,
        roles: [...this.currentUser.Roles || []],
        defaultCurrency: this.defaultCurrency?.Name,
        ccyExposures: ccyExposures.map(x => x.Name)
      };
      const fxVolatilityResp = await this.loadFxVolatility(fxVolatilityPayload); 
      this.fxVolatility = fxVolatilityResp;
      
      this.recalculateWeights();
     
    },
    async loadUser()  {
      this.isLoadUser = true;
      try {
        const resp = await http.get(`data/User/objects/current`, {
          $orderby: 'Order asc'
        });
        
        this.currentUser = resp.data;
      }
      finally {
        this.isLoadUser = false;
      }
    },
    onDefaultCurrencyChanged() {
      // console.log('onDefaultCurrencyChanged', this.defaultCurrency.Name);
       //console.log('onDefaultCurrencyChanged1', this.editedCcyExposures);
      
      let o = this.editedCcyExposures.filter(x => this.defaultCurrency.CurrencyExposures.find(a =>a.Name === x.Name));
      //console.log('onDefaultCurrencyChanged2', o);
      if(o.length === 0 && this.defaultCurrency.CurrencyExposures.length > 0) {
        o = [{...this.defaultCurrency.CurrencyExposures[0]}]
      }
      this.editedCcyExposures = o;
    },
    getCommodityProductCell(product){
      const p = this.defaultCommodity.find(x => x.product === product);
      return p?.liability ? `Having Liabilities in ${product}` : `Holding Assets in ${product}`;
    },
    selectTab(tabName) {
      this.selectedTab = tabName;
      localStorage.setItem('fx_hedge_index_tab', tabName);
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" :is-busy="loading || recalculating"/>
    <b-modal
     
     size="md"
     v-model="newPortfolioDialogVisible"
     title="Settings"
     title-class="text-dark font-18"
     hide-footer
   >
     <dt v-if="newPortfolioObj">
       <div class="form-group">
         <label>Portfolio Name</label>
         <input class="form-control" type="text" v-model="newPortfolioObj.Name"/>
       </div>
       <div class="form-group">
          <label>Identifier</label>
          <input class="form-control" type="text" v-model="newPortfolioObj.ExternalSymbol"/>
        </div>
        <div class="form-group">
          <label>Description</label>
          <textarea class="form-control" rows="5" v-model="newPortfolioObj.Description">

          </textarea>
        </div>
       <div class="form-group d-flex justify-content-between">
         <button @click="hideNewPortfolioDialog" class="btn btn-secondary">
           Close
         </button>
         <button type="button" class="btn btn-primary" 
           @click="submitNewPortfolio" :disabled="isSaving || !portfolioName">
           <i class="fa fa-save" v-if="!isSaving"></i> 
           <i class="fa fa-spin fa-spinner" v-if="isSaving"></i> 
           Save
         </button>
       </div>
     </dt>
   </b-modal>
    <b-modal
     
      size="md"
      v-model="settingModalVisible"
      title="Settings"
      title-class="text-dark font-18"
      hide-footer
    >
      <dt>
        <div class="form-group">
          <label>Portfolio Name</label>
          <input class="form-control" type="text" v-model="portfolioName"/>
        </div>
        <div class="form-group">
          <label>Identifier</label>
          <input class="form-control" type="text" v-model="portfolioExternalSymbol"/>
        </div>
        <div class="form-group">
          <label>Base Currency</label>
          <multiselect 
            v-model="defaultCurrency" 
            @input="onDefaultCurrencyChanged"
            track-by="Id"
            label="DisplayName"
            value="Id"
            placeholder="Base currency"
            :options="currencies" 
            :multiple="false">
          </multiselect>
        </div>
        <div class="form-group" v-if="defaultCurrency">
          <label>Currency exposures</label>
         
          <multiselect 
            v-model="editedCcyExposures" 
            
            track-by="Id"
            label="DisplayName"
            value="Id"
            placeholder="Currencies"
            :options="defaultCurrency.CurrencyExposures" 
            :multiple="true">
          </multiselect>
        </div>
        <div class="form-group">
          <label>Description</label>
          <textarea class="form-control" rows="5" v-model="portfolioDescription">

          </textarea>
        </div>
        <div class="form-group d-flex justify-content-between">
          <button @click="cancelSettingsDialog" class="btn btn-secondary">
            Close
          </button>
          <button type="button" class="btn btn-primary" 
            @click="onSettingsSave" :disabled="!defaultCurrency || isSaving || !portfolioName">
            <i class="fa fa-save" v-if="!isSaving"></i> 
            <i class="fa fa-spin fa-spinner" v-if="isSaving"></i> 
            Save
          </button>
        </div>
      </dt>
    </b-modal>
    <b-modal
      size="md"
      v-model="settingCommoditiesModalVisible"
      title="Settings"
      title-class="text-dark font-18"
      hide-footer
    >
      <dt>
        <div class="form-group">
          <label>Product</label>
          <multiselect 
            v-model="editedDefaultCommodity" 
            track-by="Id"
            label="DisplayName"
            value="Id"
            placeholder="Product"
            :options="commodities" 
            :multiple="true">
          </multiselect>
        </div>
        
        <div class="form-group d-flex justify-content-between">
          <button @click="hideCommoditySettingsDialog" class="btn btn-secondary">
            Close
          </button>
          <button type="button" class="btn btn-primary" 
            @click="onSaveComodities"
            :disabled="!editedDefaultCommodity || isSaving">
            <i class="fa fa-save" v-if="!isSaving"></i> 
            <i class="fa fa-spin fa-spinner" v-if="isSaving"></i> 
            Save
          </button>
        </div>
      </dt>
    </b-modal>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)" :class="{'active': selectedTab === 'hedge'}" @click="selectTab('hedge')">FX</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)" :class="{'active': selectedTab === 'fx-futures'}" @click="selectTab('fx-futures')">FX Futures Performance </a>
      </li>
      <!--
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)" :class="{'active': selectedTab === 'commodities'}" @click="selectTab('commodities')">{{ commoditiesTabTitle }}</a>
      </li>
      -->
    </ul>

    <div class="tab-content pt-4">
      <div class="tab-pane fade " :class="{'show active':selectedTab === 'hedge'}">
       
        <div class="row" v-if="currentUser">
          <!-- <div class="col-md-auto col-sm-12">
            <div style="width: 250px;">
              <div class="card border">
                <div class="card-header">
                  <h4 class="card-title">Portfolios</h4>
                </div>
                <div class="list-group list-group-flush">
                  <a
                    href="javascript:void(0)" 
                    class="list-group-item d-flex justify-content-between"
                    :class="{'active': p.Id === selectedPortfolioId}" 
                    v-for="p in portfolios" :key="p.Id"
                    @click="selectPortfolio(p.Id)"
                  >
                    <div>
                      {{ p.Name }}
                    </div>
                    <div>
                      <i class="fa fa-spin fa-spinner" v-if="selectingPortfolio && selectedPortfolioId == p.Id"></i> 
                    </div>
                  </a>
                </div>
                <div class="card-footer text-center">
                  <a href="#" class="card-link" @click="showNewPortfolioDialog">
                    <i class="fa fa-plus mr-1"></i>
                    Create New Portfolio
                  </a>
                </div>
              </div>
            </div>
            
          </div> -->
          <div class="col-sm-12">
            <div class="d-flex justify-content-center mt-4" v-if="recalculating">
              <span style="font-size: 3rem;" class=" mt-4 fa fa-spin fa-spinner"></span>
            </div>
          
            <div v-if="!recalculating && fxHedgeData && fxHedgeData.TableData">
              <div class="d-flex justify-content-between ">
                <div class="d-flex align-items-center flex-wrap">
                  <b-dropdown text="Dropdown with form" ref="dropdown" class="mb-4 mr-4" variant="outline-primary">
                    <template #button-content>
                      <div class="d-flex justify-content-between">
                        <span>Portfolio: {{ currentPortfolioName }}</span>
                        <span>
                          <i class="ml-2 fa fa-spin fa-spinner" v-if="selectingPortfolio"></i> 
                          <i class="ml-2 fa fa-caret-down"></i>
                        </span>
                       
                      </div>
                    </template>
                    <b-dropdown-header id="dropdown-header-label">
                      Portfolios
                    </b-dropdown-header>
                    <b-dropdown-form style="min-width: 300px;">
                      <div class="list-group border">
                        <a
                          href="javascript:void(0)" 
                          class="list-group-item"
                          :class="{'active': p.Id === selectedPortfolioId}" 
                          v-for="p in portfolios" :key="p.Id"
                          @click="selectPortfolio(p.Id)"
                        >
                          <div>
                            {{ p.Name }}
                          </div>
                        </a>
                      </div>
                    </b-dropdown-form>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item-button @click="showNewPortfolioDialog()" class="text-center">
                      <i class="fa fa-plus mr-1"></i>
                      Create New Portfolio
                    </b-dropdown-item-button>
                  </b-dropdown>
                  <button type="button" class="btn btn-primary mr-4 mb-4" @click="showSettingsDialog()">Settings</button>
                  <b-form-checkbox class="mb-4" v-model="currencyPortfolioSettings.OverallPortfolioLimits" @change="onOverallPortfolioLimits(currencyPortfolioSettings)">
                    <b>Portfolio Risk Limit</b>
                  </b-form-checkbox>
                </div>
                <div>
                  <b-dropdown left variant="outline-secondary" :disabled="isDeleting || isSaving">
                    <template v-slot:button-content>
                      <i class="fa fa-spin fa-spinner m-0 font-size-20" v-if="isDeleting"></i> 
                      <i class="mdi mdi-dots-vertical m-0 font-size-20" v-else></i>
                    </template>
                    <b-dropdown-item @click="showNewPortfolioDialog(selectedPortfolioId)">
                      <i class="fa fa-copy mr-1"></i> 
                      Copy Portfolio
                    </b-dropdown-item>
                    <b-dropdown-item @click="confirmDelete()" v-if="portfolios.length > 1">
                      <i class="fa fa-trash mr-1"></i> 
                      Delete Portfolio
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="table-responsive d-none d-md-block" style="position: relative; min-height:300px; width: auto;">
                <table class="table table-centered mb-0 table-nowrap">
                  <thead >
                    <tr>
                      <th style="max-width:100px; width:100px;">
                        <!-- <button type="button" class="btn btn-sm btn-primary" @click="showSettingsDialog()">Settings</button> -->
                      </th>
                      <th style="max-width:50px; width:50px;"></th>
                      <template v-for="propName in Object.keys(fxHedgeData.TableData[0] || {})" >
                        <th class="text-left" :key="propName" v-if="!isColumnHidden(fxHedgeData.TableData[0][propName])">
                          {{ fxHedgeData.TableData[0][propName] }}
                          <span class="ms-2" style="cursor:pointer" v-if="fxHedgeData.FactorDescriptions[fxHedgeData.TableData[0][propName]]"  
                            v-b-tooltip.hover :title="fxHedgeData.FactorDescriptions[fxHedgeData.TableData[0][propName]]">[?]</span>
                        </th>
                      </template>
                      <!-- <th class="text-center">
                        
                      </th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(row, rowIndex) in sortTableData(fxHedgeData.TableData)" >
                      <tr :key="rowIndex" v-if="rowIndex > 0">
                        <td v-if="row[0] !== 'Portfolio'">
                          <router-link :to="{ name: 'fx-hedge-details', params: { target: row[0], desc: 'CURRENCIES', id: selectedPortfolioId}}">Performance</router-link> 
                        </td>
                        <td v-else>
                          
                          <a href="javascript:void(0)" @click="onPortfolioPerformance(row[0])">Performance</a> 
                        </td>
                        <td>
                          <template v-if="row[0] !== 'Portfolio'">
                            <a href="javascript:void(0)" class="px-2" @click="editCurrencySettings(row[0])"><i class="fa fa-edit"></i></a>
                          </template>
                          <template v-else>
                            <span>
                              <a href="javascript:void(0)" class="px-2" @click="editCurrencySettings(row[0])"><i class="fa fa-edit"></i></a>
                            </span>
                          </template>
                        </td>
                        <template v-for="propName in Object.keys(fxHedgeData.TableData[0] || {})" >
                          <td class="text-left" :key="propName" v-if="!isColumnHidden(fxHedgeData.TableData[0][propName]) ">
                            <span v-if="isRecommededHedgeRatio(fxHedgeData.TableData[0][propName])" >
                              
                              <div class=" d-flex justify-content-between align-self-center" style="min-width: 150px;" v-if="!isColumnPortfolio(row[0])">
                                <template v-if="isLiability(row[0])">
                                  <div class="progress flex-fill" style="height:25px; position: relative;background-color: #1cbb8c;">
                                    <div 
                                      class="progress-bar bg-secondary" 
                                      role="progressbar" 
                                      :style="'width:' + calculatePerc(row[0], row[propName]) + '%'" >
                                    
                                    </div>
                                    <div style="position: absolute; left:5px; top:0; bottom:0; line-height: 25px; color: white;" class="d-flex justify-content-center align-self-start">
                                      {{ getHedgeRiskMin(row[0]) }}
                                    </div>

                                    <div style="position: absolute; left:0; right:0; bottom:0; line-height: 25px; color: white;" class=" d-flex justify-content-center align-self-center">
                                      <span v-if="row[propName] === 'NaN'">No Solution Found</span>
                                      <span v-else>  {{ roundIfNumber(row[propName]) }}%</span>
                                    
                                    </div>
                                    
                                    <div style="position: absolute;  right:5px;  bottom:0; line-height: 25px; color: white;" class="d-flex justify-content-center align-self-end">
                                      {{ getHedgeRiskMax(row[0]) }}
                                    </div>
                                  </div>
                                </template>
                                <template v-else>
                                  <div class="progress flex-fill" style="height:25px; position: relative;background-color: #74788d;">
                                    <div 
                                      class="progress-bar bg-success" 
                                      role="progressbar" 
                                      :style="'width:' + calculatePerc(row[0], row[propName]) + '%'" >
                                    
                                    </div>
                                    <div style="position: absolute; left:5px; top:0; bottom:0; line-height: 25px; color: white;" class="d-flex justify-content-center align-self-start">
                                      {{ getHedgeRiskMin(row[0]) }}
                                    </div>

                                    <div style="position: absolute; left:0; right:0; bottom:0; line-height: 25px; color: white;" class=" d-flex justify-content-center align-self-center">
                                      <span v-if="row[propName] === 'NaN'">No Solution Found</span>
                                      <span v-else>  {{ roundIfNumber(row[propName]) }}%</span>
                                    
                                    </div>
                                    
                                    <div style="position: absolute;  right:5px;  bottom:0; line-height: 25px; color: white;" class="d-flex justify-content-center align-self-end">
                                      {{ getHedgeRiskMax(row[0]) }}
                                    </div>
                                  </div>
                                </template>
                                
                              
                              </div>
                              <div v-else class=" d-flex flex-column gap-3 justify-content-between align-self-center" style="min-width: 200px;" >
                               
                                  <div v-if="getPortfolioValue(row[propName], 0) !== undefined">
                                  
                                    <small>Portfolio Risk Limit: Holding Assets</small>
                                    <div  class="progress flex-fill" style="height:25px; position: relative;background-color: #74788d;">
                                      <div 
                                        class="progress-bar bg-success" 
                                        role="progressbar" 
                                        :style="'width:' + calculatePortfolioProgressbarPerc(row[propName], 0) + '%'" >
                                      
                                      </div>
                                      <div style="position: absolute; left:5px; top:0; bottom:0; line-height: 25px; color: white;" class="d-flex justify-content-center align-self-start">
                                        {{ getCurrencyHoldingAssetRiskToleranceMin() }}
                                      </div>

                                      <div style="position: absolute; left:0; right:0; bottom:0; line-height: 25px; color: white;" class=" d-flex justify-content-center align-self-center">
                                        <span v-if="row[propName] === 'NaN'">No Solution Found</span>
                                        <span v-else>{{ getPortfolioValue(row[propName], 0) }}%</span>
                                      
                                      </div>
                                      
                                      <div style="position: absolute;  right:5px;  bottom:0; line-height: 25px; color: white;" class="d-flex justify-content-center align-self-end">
                                        {{ getCurrencyHoldingAssetRiskToleranceMax() }}
                                      </div>
                                    </div>
                                </div>
                                <div v-if="getPortfolioValue(row[propName], 1)  !== undefined" class="mt-2">
                                  <small>Portfolio Risk Limit: Having Liabilities</small>
                                  <div  class="progress flex-fill" style="height:25px; position: relative;background-color: #74788d;">
                                    <div 
                                      class="progress-bar bg-success" 
                                      role="progressbar" 
                                      :style="'width:' + calculatePortfolioProgressbarPerc(row[propName], 1) + '%'" >
                                    
                                    </div>
                                    <div style="position: absolute; left:5px; top:0; bottom:0; line-height: 25px; color: white;" class="d-flex justify-content-center align-self-start">
                                      {{ getCurrencyHoldingLiabilitiesRiskToleranceMin() }}
                                    </div>

                                    <div style="position: absolute; left:0; right:0; bottom:0; line-height: 25px; color: white;" class=" d-flex justify-content-center align-self-center">
                                      <span v-if="row[propName] === 'NaN'">No Solution Found</span>
                                      <span v-else>{{ getPortfolioValue(row[propName], 1) }}%</span>
                                
                                    </div>
                                    
                                    <div style="position: absolute;  right:5px;  bottom:0; line-height: 25px; color: white;" class="d-flex justify-content-center align-self-end">
                                      {{ getCurrencyHoldingLiabilitiesRiskToleranceMax() }}
                                    </div>
                                  </div>
                                </div>
                               
                               
                                
                              </div>
                            </span>
                            <span v-else-if="fxHedgeData.TableData[0][propName] === 'FX Drivers' || fxHedgeData.TableData[0][propName] === 'Drivers' ||  fxHedgeData.TableData[0][propName] === 'Drivers for Hedge Ratio'">
                              <span class="mr-1" v-for="(fxItem, fxItemIndex)  in splitFxDriverString(row[propName])" :key="fxItemIndex">
                               
                                <span v-for="(w, wIndex) in splitFxDriverToWords(fxItem)" :key="wIndex">
                                  <span v-if="('[' + extractSquareBracketsContent(fxItem).trim() + ']') ===  w.trim()" >&nbsp;<a href="javascript:void(0)"  v-b-tooltip.hover :title="fxHedgeData.FactorDescriptions[extractSquareBracketsContent(fxItem)]">
                                      {{ extractSquareBracketsContent(fxItem).trim() }}</a></span>
                                  <span v-else >&nbsp;{{ w.trim() }}</span></span>;
                              </span>
                             
                            </span>
                            <span v-else>
                              <span v-if="fxHedgeData.TableData[0][propName] === getHeaderCurrencyVsColumnName() && row[propName] !== 'Portfolio'">
                                <span v-if="isLiability(row[propName])">
                                  Having Liabilities in {{ row[propName] }}
                                </span>
                                <span v-else>
                                  Holding Assets in {{ row[propName] }}
                                </span>
                              </span>
                              <span v-else>
                            
                                <template v-if="fxHedgeData.TableData[0][propName] === 'Position' && row[fxHedgeData.TableData[0].indexOf('Hedge Ratio %')] === 'NaN'">
                                  <span></span>
                                </template>
                                <template v-else>
                                  {{ roundIfNumber(row[propName]) }}
                                </template>
                              </span>
                            </span> 
                           
                          </td>
                        </template>
                       
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="d-block d-md-none">
                <div v-for="(row, rowIndex) in fxHedgeData.TableData"  :key="rowIndex" >
                  <div class="card border" v-if="rowIndex > 0">
                    <div class="card-body" >
                      <h5 class="card-title">{{fxHedgeData.TableData[0][Object.keys(fxHedgeData.TableData[0] || {})[0]]}} {{ row[0] }}</h5>
                      <div v-for="(propName, propIndex) in Object.keys(fxHedgeData.TableData[0] || {})" :key="propName">
                        <div class="row mb-2" v-if="!isColumnHidden(fxHedgeData.TableData[0][propName]) && propIndex > 0">
                          <label class="text-muted col-4">{{ fxHedgeData.TableData[0][propName] }}:</label>
                          
                          <div class="col-8">
                            <span v-if="isRecommededHedgeRatio(fxHedgeData.TableData[0][propName])" >
                                
                                <div class=" d-flex justify-content-between align-self-center" style="min-width: 150px; ;" v-if="!isColumnPortfolio(row[0])">
                              
                                  <div class="progress flex-fill" style="height:25px; position: relative;background-color: #74788d;">
                                    <div 
                                      class="progress-bar bg-success" 
                                      role="progressbar" 
                                      :style="'width:' + calculatePerc(row[0], row[propName]) + '%'" >
                                    
                                    </div>
                                    <div style="position: absolute; left:5px; top:0; bottom:0; line-height: 25px; color: white;" class="d-flex justify-content-center align-self-start">
                                      {{ getHedgeRiskMin(row[0]) }}
                                    </div>

                                    <div style="position: absolute; left:0; right:0; bottom:0; line-height: 25px; color: white;" class=" d-flex justify-content-center align-self-center">
                                      {{ roundIfNumber(row[propName]) }}%
                                    </div>
                                    
                                    <div style="position: absolute;  right:5px;  bottom:0; line-height: 25px; color: white;" class="d-flex justify-content-center align-self-end">
                                      {{ getHedgeRiskMax(row[0]) }}
                                    </div>
                                </div>
                                
                                </div>
                                <div v-else class=" d-flex flex-column gap-3 justify-content-between align-self-center" style="min-width: 200px;" >
                                  
                                  <div v-if="getPortfolioValue(row[propName], 0) !== undefined">
                                    
                                      <small>Portfolio Risk Limit: Holding Assets</small>
                                      <div  class="progress flex-fill" style="height:25px; position: relative;background-color: #74788d;">
                                        <div 
                                          class="progress-bar bg-success" 
                                          role="progressbar" 
                                          :style="'width:' + calculatePortfolioProgressbarPerc(row[propName], 0) + '%'" >
                                        
                                        </div>
                                        <div style="position: absolute; left:5px; top:0; bottom:0; line-height: 25px; color: white;" class="d-flex justify-content-center align-self-start">
                                          {{ getCurrencyHoldingAssetRiskToleranceMin() }}
                                        </div>

                                        <div style="position: absolute; left:0; right:0; bottom:0; line-height: 25px; color: white;" class=" d-flex justify-content-center align-self-center">
                                          {{ getPortfolioValue(row[propName], 0) }}%
                                        </div>
                                        
                                        <div style="position: absolute;  right:5px;  bottom:0; line-height: 25px; color: white;" class="d-flex justify-content-center align-self-end">
                                          {{ getCurrencyHoldingAssetRiskToleranceMax() }}
                                        </div>
                                      </div>
                                  </div>
                                  <div v-if="getPortfolioValue(row[propName], 1)  !== undefined" class="mt-2">
                                    <small>Portfolio Risk Limit: Having Liabilities</small>
                                    <div  class="progress flex-fill" style="height:25px; position: relative;background-color: #74788d;">
                                      <div 
                                        class="progress-bar bg-success" 
                                        role="progressbar" 
                                        :style="'width:' + calculatePortfolioProgressbarPerc(row[propName], 1) + '%'" >
                                      
                                      </div>
                                      <div style="position: absolute; left:5px; top:0; bottom:0; line-height: 25px; color: white;" class="d-flex justify-content-center align-self-start">
                                        {{ getCurrencyHoldingLiabilitiesRiskToleranceMin() }}
                                      </div>

                                      <div style="position: absolute; left:0; right:0; bottom:0; line-height: 25px; color: white;" class=" d-flex justify-content-center align-self-center">
                                        {{ getPortfolioValue(row[propName], 1) }}%
                                      </div>
                                      
                                      <div style="position: absolute;  right:5px;  bottom:0; line-height: 25px; color: white;" class="d-flex justify-content-center align-self-end">
                                        {{ getCurrencyHoldingLiabilitiesRiskToleranceMax() }}
                                      </div>
                                    </div>
                                  </div>
                                  
                                </div>
                              </span>
                              <span v-else-if="fxHedgeData.TableData[0][propName] === 'FX Drivers' || fxHedgeData.TableData[0][propName] === 'Drivers' ||  fxHedgeData.TableData[0][propName] === 'Drivers for Hedge Ratio'">
                                <span class="mr-1" v-for="(fxItem, fxItemIndex)  in splitFxDriverString(row[propName])" :key="fxItemIndex">
                                
                                  <span v-for="(w, wIndex) in splitFxDriverToWords(fxItem)" :key="wIndex">
                                    <span v-if="('[' + extractSquareBracketsContent(fxItem).trim() + ']') ===  w.trim()" >&nbsp;<a href="javascript:void(0)"  v-b-tooltip.hover :title="fxHedgeData.FactorDescriptions[extractSquareBracketsContent(fxItem)]">
                                        {{ extractSquareBracketsContent(fxItem).trim() }}</a></span>
                                    <span v-else >&nbsp;{{ w.trim() }}</span></span>;
                                </span>
                              
                              </span>
                              <span v-else>
                                <span v-if="fxHedgeData.TableData[0][propName] === getHeaderCurrencyVsColumnName() && row[propName] !== 'Portfolio'">
                                  <span v-if="isLiability(row[propName])">
                                    Having Liabilities in {{ row[propName] }}
                                  </span>
                                  <span v-else>
                                    Holding Assets in {{ row[propName] }}
                                  </span>
                                </span>
                                <span v-else> {{ roundIfNumber(row[propName]) }}</span>
                              </span>
                           
                          </div>

                        </div>
                      </div>
                   
                    </div>
                    <hr/>
                    <div class="card-body" >
                      <div class="card-text d-flex justify-content-between">
                        <div>
                          <span v-if="row[0] !== 'Portfolio'">
                            <router-link :to="{ name: 'fx-hedge-details', params: { target: row[0], desc: 'CURRENCIES', id: selectedPortfolioId}}">Performance</router-link> 
                          </span>
                          <span v-else>
                            <a href="javascript:void(0)" @click="onPortfolioPerformance(row[0])">Performance</a> 
                          </span>
                        </div>
                        <div>
                          <template v-if="row[0] !== 'Portfolio'">
                            <a href="javascript:void(0)" class="px-2" @click="editCurrencySettings(row[0])"><i class="fa fa-edit"></i></a>
                          </template>
                          <template v-else>
                            <span>
                              <a href="javascript:void(0)" class="px-2" @click="editCurrencySettings(row[0])"><i class="fa fa-edit"></i></a>
                            </span>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>    
          </div>  
        </div>
      </div>
      <div class="tab-pane fade" :class="{'show active':selectedTab === 'fx-futures'}">
        <div class="card cardc8" v-if="currentUser" style="position: relative;">
          <div class="card-content">
            <div class="card-body" >
              <div class="row" >
                <div class="col-12">
                  <div class="form-group">
                    <label>Base Currency</label>
                    <multiselect 
                      v-model="chartsCurrency" 
                      @input="loadCharts"
                      track-by="Id"
                      label="DisplayName"
                      value="Id"
                      placeholder="Currency"
                      :options="currencies" 
                      :multiple="false">
                    </multiselect>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12" v-for="(crtOpt, i) in chartOptions" :key="i">
                  <highcharts 
                    :constructorType="'stockChart'" 
                    class="hc" 
                    :options="crtOpt" >
                  </highcharts>
                </div>
              </div>
            </div>
          </div>
          <div v-if="chartsLoading" style="position: absolute; background-color:  rgba(0,0,0,0.2); left:5px; right: 5px; top:5px; bottom: 5px; border-radius: 10px; text-align: center; vertical-align: middle;" class="r">
            <i class="fa fa-spin fa-spinner" style="margin-top: 30px; font-size: 3rem;"></i>
              
          </div>
        </div>
      </div>
      <div class="tab-pane fade" :class="{'show active':selectedTab === 'commodities'}">
        <div class="row" v-if="currentUser">
          <dd class="col-lg-12 text-right">
            <div class="d-flex justify-content-center mt-4" v-if="recalculating">
              <span style="font-size: 3rem;" class=" mt-4 fa fa-spin fa-spinner"></span>
            </div>
          
            <div v-if="!recalculating ">
              <div class="table-responsive d-none d-md-block" style="position: relative; min-height:300px">
                <table class="table table-centered mb-0 table-nowrap">
                  <thead >
                    <tr>
                      <th style="max-width:100px; width:100px;">
                        <button type="button" class="btn btn-sm btn-primary" @click="showCommoditySettingsDialog()">Settings</button>
                      </th>
                      <th style="max-width:50px; width:50px;"></th>
                      <template v-for="propName in Object.keys(((commoditiesData || {}).TableData || [])[0] || {})" >
                        <th class="text-left" :key="propName" v-if="!isColumnHidden(commoditiesData.TableData[0][propName])">
                          {{ commoditiesData.TableData[0][propName] }}
                          <span class="ms-2" style="cursor:pointer" v-if="commoditiesData.FactorDescriptions[commoditiesData.TableData[0][propName]]"  
                            v-b-tooltip.hover :title="commoditiesData.FactorDescriptions[commoditiesData.TableData[0][propName]]">[?]</span>
                        </th>
                      </template>
                      <th class="text-center" v-if="Object.keys(((commoditiesData || {}).TableData || [])[0] || {}).length === 0">
                        
                      </th>
                      
                    </tr>
                  </thead>
                  <tbody v-if="commoditiesData && commoditiesData.TableData && commoditiesData.TableData.length > 0">
                    <template v-for="(row, rowIndex) in commoditiesData.TableData" >
                      <tr :key="rowIndex" v-if="rowIndex > 0">
                        <td v-if="row[0] !== 'Portfolio'">
                          <router-link :to="{ name: 'fx-hedge-details', params: { target: row[0], desc: 'COMMODITIES', id: selectedPortfolioId}}">Performance</router-link> 
                        </td>
                        <td v-else>
                          <a href="javascript:void(0)" @click="showProductsPortfolioSettings('COMMODITIES', true)">Performance</a> 
                        </td>
                        <td>
                          <a  v-if="row[0] !== 'Portfolio'" href="javascript:void(0)" class="px-2" @click="showProductSettings('COMMODITIES', row[0])"><i class="fa fa-edit"></i></a>
                          <a  v-else href="javascript:void(0)" class="px-2" @click="showProductsPortfolioSettings('COMMODITIES')"><i class="fa fa-edit"></i></a>
                        </td>
                        <template v-for="propName in Object.keys(commoditiesData.TableData[0] || {})" >
                          <td class="text-left" :key="propName" v-if="!isColumnHidden(commoditiesData.TableData[0][propName]) ">
                            <span v-if="isRecommededHedgeRatio(commoditiesData.TableData[0][propName]) && !isColumnPortfolio(row[0])">
                              
                              <div class=" d-flex justify-content-between align-self-center" style="min-width: 150px;">
                             
                                <div class="progress flex-fill" style="height:25px; position: relative;background-color: #74788d;">
                                  <div 
                                    class="progress-bar bg-success" 
                                    role="progressbar" 
                                    :style="'width:' + calculatePerc(row[0], row[propName]) + '%'" >
                                   
                                  </div>
                                  <div style="position: absolute; left:5px; top:0; bottom:0; line-height: 25px; color: white;" class="d-flex justify-content-center align-self-start">
                                    {{ getCommodityHedgeRiskMin(row[0]) }}
                                  </div>

                                  <div style="position: absolute; left:0; right:0; bottom:0; line-height: 25px; color: white;" class=" d-flex justify-content-center align-self-center">
                                    {{ roundIfNumber(row[propName]) }}%
                                  </div>
                                  
                                  <div style="position: absolute;  right:5px;  bottom:0; line-height: 25px; color: white;" class="d-flex justify-content-center align-self-end">
                                    {{ getCommodityHedgeRiskMax(row[0]) }}
                                  </div>
                               </div>
                               <!-- <div class="px-2">
  A
                                </div> -->
                              </div>
                            </span>
                            <span v-else-if="commoditiesData.TableData[0][propName] === 'FX Drivers' || commoditiesData.TableData[0][propName] === 'Drivers' ||  fxHedgeData.TableData[0][propName] === 'Drivers for Hedge Ratio'">
                              <span class="mr-1" v-for="(fxItem, fxItemIndex)  in splitFxDriverString(row[propName])" :key="fxItemIndex">
                               
                                <span v-for="(w, wIndex) in splitFxDriverToWords(fxItem)" :key="wIndex">
                                  <span v-if="('[' + extractSquareBracketsContent(fxItem).trim() + ']') ===  w.trim()" >&nbsp;<a href="javascript:void(0)"  v-b-tooltip.hover :title="commoditiesData.FactorDescriptions[extractSquareBracketsContent(fxItem)]">
                                      {{ extractSquareBracketsContent(fxItem).trim() }}</a></span>
                                  <span v-else >&nbsp;{{ w.trim() }}</span></span>;
                              </span>
                             
                            </span>
                            <span v-else>
                              <span v-if="row[propName] !== 'Portfolio' && commoditiesData.TableData[0][propName] === 'Product'">
                                {{ getCommodityProductCell(row[propName]) }}
                              </span>
                              <span v-else> {{ roundIfNumber(row[propName]) }}</span>
                            </span>
                           
                          </td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </dd>     
        </div>
      </div>
    </div>
    
    <b-modal
      v-model="showEditCurrencySettings"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="onSave" v-if="editedCurrency">
        <div v-if="riskToleranceSettingsSumNotValid">
          <div class="alert alert-danger mb-4">
            <b>Risk tolerance / range for hedges</b> values are not valid.
            <ul>
              <li><b>Min</b> must be less than or equal to <b>Max</b></li>
              
            </ul>
          </div>
        </div>
        <div>

        </div>
        <div class="form-group row">
          <label class="col-md-5">Hedge Target, %</label>
          <div class=" col-md-7">
            <input class="form-control" v-model.number="editedCurrency.hedgeBenchmark"
             
            @input="validateInput(editedCurrency, 'hedgeBenchmark', -200, 200)" 
            @blur="clampValue(editedCurrency, 'hedgeBenchmark', -200, 200)"

             type="number"/>
          </div>
          
        </div>

        <div class="form-group row  bg-light pb-4">
          <label class="col-12 pt-4">Hedge Range, %</label>
          <div class="d-flex justify-content-between  col-12" >
            <div class="text-center">
              <small style="opacity: 0.8;">Lower</small><br/>
              <input class="form-control" v-model.number="editedCurrency.riskTolerance.min"
            
              @input="riskToleranceValidateInput(editedCurrency.riskTolerance, 'min')" 
              @blur="riskToleranceClampValue(editedCurrency.riskTolerance, 'min')"
              type="number"/>
            </div>
            
            <!-- <span style="width: 20px;"></span>
            <div class="text-center">
              <small style="opacity: 0.8;">Mid</small><br/>
            <input class="form-control" v-model.number="editedCurrency.riskTolerance.mid" 
            
              @input="riskToleranceValidateInput(editedCurrency.riskTolerance, 'mid')" 
              @blur="riskToleranceClampValue(editedCurrency.riskTolerance, 'mid')"
              type="number"/>
            </div> -->
            <span style="width: 20px;"></span>
            <div class="text-center">
              <small style="opacity: 0.8;">Upper</small><br/>
              <input class="form-control" v-model.number="editedCurrency.riskTolerance.max" 
            
              @input="riskToleranceValidateInput(editedCurrency.riskTolerance, 'max')" 
              @blur="riskToleranceClampValue(editedCurrency.riskTolerance, 'max')"
              type="number"/>
            </div>
            <span style="width: 20px;"></span>
            <div class="text-center">
              <small style="opacity: 0.8;">Step</small><br/>
              <input class="form-control" v-model.number="editedCurrency.riskTolerance.step" 
            
              @input="riskToleranceValidateInput(editedCurrency.riskTolerance, 'step')" 
              @blur="riskToleranceClampValue(editedCurrency.riskTolerance, 'step')"
              type="number"/>
            </div>
            <span style="width: 20px;"></span>
            <div class="text-center">
              <small style="opacity: 0.8;">Max Changes</small><br/>
              <input class="form-control" v-model.number="editedCurrency.riskTolerance.maxChanges" 
  
              type="number"/>
            </div>
          </div>
        
        </div>
        
        <div class="form-group row">
         
          <div class=" col-12">
            <input type="checkbox" class="mr-2" v-model="editedCurrency.liability" @change="onLiabilityChanged(editedCurrency)"/> 
            <span class="mr-2">  
              Liability
            </span>
          </div>  
        </div>
          
        <div class="form-group d-flex justify-content-between mt-4">
          <button type="button" class="btn btn-light" @click="onCancelEditCurrencySettings">
           
            Cancel
          </button>
          <div>
            <button type="button" class="btn btn-info mr-4" @click="onDebSaveToAll" :disabled="isSaving">
              <i class="fa fa-save" v-if="!isSaving"></i> 
              <i class="fa fa-spin fa-spinner" v-if="isSaving"></i> 
              Save All
            </button>
            <button type="button" class="btn btn-primary" @click="onDebSave" :disabled="isSaving">
              <i class="fa fa-save" v-if="!isSaving"></i> 
              <i class="fa fa-spin fa-spinner" v-if="isSaving"></i> 
              Save
            </button>
          </div>
         
        </div>
        
        <div>
         
        </div>
      </form>
    </b-modal>

    <b-modal
      v-model="productSettingsVisible"
      title-class="text-dark font-18"
      hide-footer
    >
      <form v-if="editedProductSettings">
        <div v-if="productSettingsRiskValuesNotValid">
          <div class="alert alert-danger mb-4">
            <b>Risk tolerance / range for hedges</b> values are not valid.
            <ul>
              <li><b>Min</b> must be less than or equal to <b>Max</b></li>
              
            </ul>
          </div>
        </div>
        <div>

        </div>
        <div class="form-group row">
          <label class="col-md-5">Hedge Target, %</label>
          <div class=" col-md-7">
            <input class="form-control" v-model.number="editedProductSettings.hedgeBenchmark"
             
            @input="validateInput(editedProductSettings, 'hedgeBenchmark', -200, 200)" 
            @blur="clampValue(editedProductSettings, 'hedgeBenchmark', -200, 200)"

             type="number"/>
          </div>
          
        </div>

        <div class="form-group row">
          <label class="col-md-5 pt-4">Hedge Range, %</label>
          <div class="d-flex justify-content-between  col-md-7" >
            <div class="text-center">
              <small style="opacity: 0.8;">Lower</small><br/>
              <input class="form-control" v-model.number="editedProductSettings.riskTolerance.min"
            
              @input="riskToleranceValidateInput(editedProductSettings.riskTolerance, 'min')" 
              @blur="riskToleranceClampValue(editedProductSettings.riskTolerance, 'min')"
              type="number"/>
            </div>
            
            <span style="width: 20px;"></span>
            <div class="text-center">
              <small style="opacity: 0.8;">Upper</small><br/>
              <input class="form-control" v-model.number="editedProductSettings.riskTolerance.max" 
            
              @input="riskToleranceValidateInput(editedProductSettings.riskTolerance, 'max')" 
              @blur="riskToleranceClampValue(editedProductSettings.riskTolerance, 'max')"
              type="number"/>
            </div>
            <span style="width: 20px;"></span>
            <div class="text-center">
              <small style="opacity: 0.8;">Max Changes</small><br/>
              <input class="form-control" v-model.number="editedProductSettings.riskTolerance.maxChanges" 
              type="number"/>
            </div>
          </div>
        
        </div>
        
        <div class="form-group row">
          <label class="col-md-5"></label>
          <div class=" col-md-7">
            <input type="checkbox" class="mr-2" v-model="editedProductSettings.liability" @change="onLiabilityChanged(editedProductSettings)"/> 
            <span class="mr-2">  
              Liability
            </span>
          </div>  
        </div>
          
        <div class="form-group d-flex justify-content-between">
          <button type="button" class="btn btn-light" @click="hideProductSettings">
           
            Cancel
          </button>
          <div>
            <button type="button" class="btn btn-info mr-4" @click="onProductSettingsSaveToAll" :disabled="isSaving">
              <i class="fa fa-save" v-if="!isSaving"></i> 
              <i class="fa fa-spin fa-spinner" v-if="isSaving"></i> 
              Save All
            </button>
            <button type="button" class="btn btn-primary" @click="onProductSettingsSave" :disabled="isSaving">
              <i class="fa fa-save" v-if="!isSaving"></i> 
              <i class="fa fa-spin fa-spinner" v-if="isSaving"></i> 
              Save
            </button>
          </div>
         
        </div>
        
        <div>
         
        </div>
      </form>
    </b-modal>

    <b-modal
      v-model="showEditPortfolioSettings"
      title-class="text-dark font-18"
      hide-footer
      size="lg"
      title="Portfolio Settings"
      no-close-on-esc
      no-close-on-backdrop

    >
      <form @submit.prevent="onSave" v-if="showEditPortfolioSettings">
        <div class="mb-4 mt-4 p-4" v-if="currencySettings.length > 0">
          <div v-if="portfolioCurrencySettingsSumNotValid">
            <div class="alert alert-danger mb-4">
              The value of the Position cannot be zero.
              <!-- The sum of the weights should be <b>100</b>. Now the sum is: <b>{{ portfolioCurrencySettingsSum }}. Set positions other than zero.</b> -->
            </div>
          </div>
          
          <table class="table  table-sm ">
            <thead>
              <tr>
                <th></th>
                <th>Position</th>
                <th>Weight</th>
                <th>
                  <span v-if="currencyPortfolioSettings.RiskFactor === 'STATIC'">Static</span>
                  <span v-else-if="currencyPortfolioSettings.RiskFactor === 'IMPLIED_VOL'">Implied Vol (3m), %</span>
                  <span v-else-if="currencyPortfolioSettings.RiskFactor === 'HISTORICAL_VOL'">Historical Vol (3m), %</span>
                </th>
                <th>Override Latest</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(pr, prIndex) in currencySettings" :key="prIndex">
                <td class="d-flex justify-content-center align-items-center">
                  <b class="mt-2 d-block">{{ pr.currency.Name }}</b>
                </td>
                <td>
                  <input v-model.number="pr.position" class="form-control input-sm" type="number" min="0" @input="validateInput(pr, 'position', 0, 100000000)" @blur="clampValue(pr, 'position', 0, 100000000)"/>
                </td>
                <td class="d-flex align-items-center justify-content-center">
                  <b>{{ pr.weight + '%' }}</b>
                  <!-- <input :value="pr.weight + '%'" class="form-control input-sm" min="0" max="100" readonly disabled/> -->
                </td>
                <td>
                  <!-- <div class="input-group "> -->
                    <input v-model.number="pr.riskFactor" class="form-control " type="number" :readonly="pr.overrideLatest !== true" @change="portfolioSettingsRiskFactorValueChanged(pr)"/>
                    <!-- <div class="input-group-append">
                      <button type="button" class="btn btn-secondary"
                      @click="pr.riskFactor = getCurrencyRiskFactor(pr.currency.Name)"
                      >
                        Set Default
                      </button>
                    </div>  -->
                  <!-- </div> -->
                </td>
                <td class="d-flex align-items-center justify-content-center">
                  <input v-model="pr.overrideLatest" type="checkbox"  @change="recalculateWeights"/>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group d-flex align-items-center px-2 pt-2">
                <b class="mr-3"> Risk Factor</b>
                <span style="display:inline-block"  >
                  <b-form-radio-group
                    v-model="currencyPortfolioSettings.RiskFactor"
                    @change="portfolioSettingsRiskFactorTypeChanged"
                    :options="riskFactorOptions"
                    button-variant="outline-secondary"
                    name="riskFactor"
                    buttons
                  ></b-form-radio-group>
                  
                </span>
              </div>
            </div>
          </div>
         
          <b-card class="mt-4 bg-light">
            <div class="d-flex justify-content-between">
              <h4 class="mb-3">
                Portfolio Risk Limit
               
                <span ></span>
              </h4>
              <div class="d-flex gap-3">
                <div class="mr-4">
                  <b-button 
                    @click="setPortfolioRiskRoleranceMode('Simple')"
                    size="sm"
                    :variant="currencyPortfolioSettings.RiskPortfolioFormRiskToleranceMode === 'Simple' ? 'secondary' : 'outline-secondary'">
                    Simple
                  </b-button>
                  <a href="javascript:void(0)" v-b-tooltip.hover.html="getPortfolioFormRiskToleranceModeHelp('Simple')" triggers="click">
                    [?]
                  </a>
                 
                </div>
                <div>
                  <b-button 
                    size="sm"
                    @click="setPortfolioRiskRoleranceMode('WithRiskOffset')"
                    :variant="currencyPortfolioSettings.RiskPortfolioFormRiskToleranceMode !== 'Simple' ? 'secondary' : 'outline-secondary'">
                    With Risk Offset
                  </b-button>
                  <a href="javascript:void(0)" v-b-tooltip.hover.html="getPortfolioFormRiskToleranceModeHelp('WithRiskOffset')" triggers="click">
                    [?]
                  </a>
                </div>
              </div>
             
              <!-- <b-form-radio-group
                v-model="currencyPortfolioSettings.RiskPortfolioFormRiskToleranceMode"
                :options="riskPortfolioFormRiskToleranceModeOptions"
                button-variant="outline-secondary"
                name="riskPortfolioFormRiskToleranceMode"
                buttons
                class="mb-1"
                size="small"
                \
              ></b-form-radio-group> -->
            </div>
           
            <div class="form-group">
            
              <div class="row" v-if="!isAllLiability()">
                <label class="col-md-5">
                  <div class="mt-4"><b>Holding Asset</b></div>
                  Risk tolerance / range for hedges, %
                </label>
                <div class="  col-md-7" >
                  <div class="text-right">
                  
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="text-center">
                      <small style="opacity: 0.8;">Min</small><br/>
                      <input class="form-control" v-model.number="currencyPortfolioSettings.HoldingAssetRiskToleranceMin"
                    
                      @input="riskToleranceValidateInput(currencyPortfolioSettings, 'HoldingAssetRiskToleranceMin')" 
                      @blur="riskToleranceClampValue(currencyPortfolioSettings, 'HoldingAssetRiskToleranceMin')"
                      type="number"/>
                    </div>
                    <span style="width: 20px;"></span>
                    <div class="text-center">
                      <small style="opacity: 0.8;">
                        <span v-if="currencyPortfolioSettings.RiskPortfolioFormRiskToleranceMode === 'WithRiskOffset'">Target (set below)</span>
                        <span v-else>Target</span>
                        
                      </small><br/>
                      <input 
                        class="form-control" 
                        v-model.number="currencyPortfolioSettings.HoldingAssetTarget" 
                        type="number" 
                        :readonly="currencyPortfolioSettings.RiskPortfolioFormRiskToleranceMode === 'WithRiskOffset'"
                        :disabled="currencyPortfolioSettings.RiskPortfolioFormRiskToleranceMode === 'WithRiskOffset'"
                        />
                    </div>
                    <span style="width: 20px;"></span>
                    <div class="text-center">
                      <small style="opacity: 0.8;">Max</small><br/>
                      <input class="form-control" v-model.number="currencyPortfolioSettings.HoldingAssetRiskToleranceMax" 
                    
                      @input="riskToleranceValidateInput(currencyPortfolioSettings, 'HoldingAssetRiskToleranceMax')" 
                      @blur="riskToleranceClampValue(currencyPortfolioSettings, 'HoldingAssetRiskToleranceMax')"
                      type="number"/>
                    </div>
                  </div>  
                </div>
                <div class="col-md-12 text-danger mb-3" v-if="isPortfolioHoldingAssetInvalid">
                  <b>Min</b> must be less than or equal to <b>Target</b>. <b>Target</b> must be less than or equal to <b>Max</b>.
                </div>
              </div>
            </div>
           
            <div class="form-group " v-if="!isAllAssets()">
              <div class="row">
                <label class="col-md-5">
                  <div><b>Having Liabilities</b></div>
                  Risk tolerance / range for hedges, %
                </label>
                <div class="d-flex justify-content-between  col-md-7" >
                  <div class="text-center">
                    <small style="opacity: 0.8;">Min</small><br/>
                    <input class="form-control" v-model.number="currencyPortfolioSettings.HoldingLiabilitiesRiskToleranceMin"
                  
                    @input="riskToleranceValidateInput(currencyPortfolioSettings, 'HoldingLiabilitiesRiskToleranceMin')" 
                    @blur="riskToleranceClampValue(currencyPortfolioSettings, 'HoldingLiabilitiesRiskToleranceMin')"
                    type="number"/>
                  </div>
                  <span style="width: 20px;"></span>
                  <div class="text-center">
                    <small style="opacity: 0.8;">
                      <span v-if="currencyPortfolioSettings.RiskPortfolioFormRiskToleranceMode === 'WithRiskOffset'">Target (set below)</span>
                      <span v-else>Target</span>
                      
                    </small>
                    <br/>
                    <input 
                      class="form-control" 
                      v-model.number="currencyPortfolioSettings.HoldingLiabilitiesTarget" 
                      type="number" 
                      :readonly="currencyPortfolioSettings.RiskPortfolioFormRiskToleranceMode === 'WithRiskOffset'"
                      :disabled="currencyPortfolioSettings.RiskPortfolioFormRiskToleranceMode === 'WithRiskOffset'"
                    />
                  </div>
                  <span style="width: 20px;"></span>
                  <div class="text-center">
                    <small style="opacity: 0.8;">Max</small><br/>
                    <input class="form-control" v-model.number="currencyPortfolioSettings.HoldingLiabilitiesRiskToleranceMax" 
                  
                    @input="riskToleranceValidateInput(currencyPortfolioSettings, 'HoldingLiabilitiesRiskToleranceMax')" 
                    @blur="riskToleranceClampValue(currencyPortfolioSettings, 'HoldingLiabilitiesRiskToleranceMax')"
                    type="number"/>
                  </div>
                </div>
                <div class="col-md-12 text-danger mb-3" v-if="isPortfolioHavingLiabilitiesInvalid">
                  <b>Min</b> must be less than or equal to <b>Target</b>. <b>Target</b> must be less than or equal to <b>Max</b>.
                </div>
              </div>
    
            </div>

            <div class="d-flex justify-content-between mt-4" v-if="currencyPortfolioSettings.RiskPortfolioFormRiskToleranceMode === 'WithRiskOffset'">
              <h4 class="mb-3">
                Individual Currency Limits    
              </h4>
             
            </div>
            <div v-if="currencyPortfolioSettings.RiskPortfolioFormRiskToleranceMode === 'WithRiskOffset'">
              <div class="form-group" >
                
                <div class="row" v-if="!isAllLiability()">
                  <label class="col-md-5">
                    <div class="mt-4"><b>Holding Asset</b></div>
                    Risk tolerance / range for hedges, %
                  </label>
                  <div class="  col-md-7" >
                    <div class="text-right">
                    
                    </div>
                    <div class="d-flex justify-content-between">
                      <div class="text-center">
                        <small style="opacity: 0.8;">Min</small><br/>
                        <input class="form-control" v-model.number="currencyPortfolioSettings.ICHoldingAssetRiskToleranceMin"
                      
                        @input="riskToleranceValidateInput(currencyPortfolioSettings, 'ICHoldingAssetRiskToleranceMin')" 
                        @blur="riskToleranceClampValue(currencyPortfolioSettings, 'ICHoldingAssetRiskToleranceMin')"
                        type="number"/>
                      </div>
                      <span style="width: 20px;"></span>
                      <div class="text-center">
                        <small style="opacity: 0.8;">Target</small><br/>
                        <input class="form-control" v-model.number="currencyPortfolioSettings.ICHoldingAssetTarget" @change="onICHoldingAssetTargetChanged(currencyPortfolioSettings)" type="number" />
                      </div>
                      <span style="width: 20px;"></span>
                      <div class="text-center">
                        <small style="opacity: 0.8;">Max</small><br/>
                        <input class="form-control" v-model.number="currencyPortfolioSettings.ICHoldingAssetRiskToleranceMax" 
                      
                        @input="riskToleranceValidateInput(currencyPortfolioSettings, 'ICHoldingAssetRiskToleranceMax')" 
                        @blur="riskToleranceClampValue(currencyPortfolioSettings, 'ICHoldingAssetRiskToleranceMax')"
                        type="number"/>
                      </div>
                    </div>  
                  </div>
                  <div class="col-md-12 text-danger mb-3" v-if="isICPortfolioHoldingAssetInvalid">
                    <b>Min</b> must be less than or equal to <b>Target</b>. <b>Target</b> must be less than or equal to <b>Max</b>.
                  </div>
                </div>
              </div>
          
              <div class="form-group " v-if="!isAllAssets()">
                <div class="row">
                  <label class="col-md-5">
                    <div><b>Having Liabilities</b></div>
                    Risk tolerance / range for hedges, %
                  </label>
                  <div class="d-flex justify-content-between  col-md-7" >
                    <div class="text-center">
                      <small style="opacity: 0.8;">Min</small><br/>
                      <input class="form-control" v-model.number="currencyPortfolioSettings.ICHoldingLiabilitiesRiskToleranceMin"
                    
                      @input="riskToleranceValidateInput(currencyPortfolioSettings, 'ICHoldingLiabilitiesRiskToleranceMin')" 
                      @blur="riskToleranceClampValue(currencyPortfolioSettings, 'ICHoldingLiabilitiesRiskToleranceMin')"
                      type="number"/>
                    </div>
                    <span style="width: 20px;"></span>
                    <div class="text-center">
                      <small style="opacity: 0.8;">Target</small><br/>
                      <input class="form-control" v-model.number="currencyPortfolioSettings.ICHoldingLiabilitiesTarget" @change="onICHoldingLiabilitiesTargetChanged(currencyPortfolioSettings)" type="number"/>
                    </div>
                    <span style="width: 20px;"></span>
                    <div class="text-center">
                      <small style="opacity: 0.8;">Max</small><br/>
                      <input class="form-control" v-model.number="currencyPortfolioSettings.ICHoldingLiabilitiesRiskToleranceMax" 
                    
                      @input="riskToleranceValidateInput(currencyPortfolioSettings, 'ICHoldingLiabilitiesRiskToleranceMax')" 
                      @blur="riskToleranceClampValue(currencyPortfolioSettings, 'ICHoldingLiabilitiesRiskToleranceMax')"
                      type="number"/>
                    </div>
                  </div>
                  <div class="col-md-12 text-danger mb-3" v-if="isICPortfolioHavingLiabilitiesInvalid">
                    <b>Min</b> must be less than or equal to <b>Target</b>. <b>Target</b> must be less than or equal to <b>Max</b>.
                  </div>
                </div>
      
              </div>
            </div>
           
          </b-card>
        </div>
        <div class="mb-4 mt-4 p-4" v-else>
          <div class="alert alert-primary">
            Please use <b>Settings</b> to add <b>Base Currency</b> and  <b>Currency Exposures</b>.
          </div>
        </div>
       
        <div class="form-group d-flex justify-content-between">
          <button type="button" class="btn btn-light" @click="hideEditPortfolioSettings() ">
            Cancel
          </button>
          <button type="button" class="btn btn-primary" @click="onSave" :disabled="isSaving" v-if="currencySettings.length > 0">
            <i class="fa fa-save" v-if="!isSaving"></i> 
            <i class="fa fa-spin fa-spinner" v-if="isSaving"></i> 
            Save
          </button>
        </div>
        
       
      </form>
    </b-modal>

    <b-modal
      v-model="productsPortfolioSettingsVisible"
      title-class="text-dark font-18"
      hide-footer
      title="Product weights"
    >
      <form @submit.prevent="onSave">
        <div class="mb-4 mt-4 p-4" v-if="productWeights.length > 0">
          <div v-if="productPortfolioWeightsSumNotValid">
            <div class="alert alert-danger mb-4">
              The sum of the weights should be <b>100</b>. Now the sum is: <b>{{ productPortfolioWeightsSum }}</b>
            </div>
          </div>
          
          <table class="table  table-sm ">
            <thead>
              <tr>
                <th>Product</th>
                <th>Weight (where 100 is equivalent to 100%) (%)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(pr, prIndex) in productWeights" :key="prIndex">
                <td class="d-flex justify-content-center align-items-center">
                  <b class="mt-2 d-block">{{ pr.product }}</b>
                </td>
                <td>
                  <input v-model.number="pr.weight" class="form-control input-sm" type="number" min="0" max="100" @input="validateInput(pr, 'weight', 0, 100)" @blur="clampValue(pr, 'weight', 0, 100)"/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mb-4 mt-4 p-4" v-else>
          <div class="alert alert-primary">
            Please use <b>Settings</b> to add <b>Products</b>.
          </div>
        </div>
       
        <div class="form-group d-flex justify-content-between">
          <button type="button" class="btn btn-light" @click="hideProductsPortfolioSettings() ">
            Cancel
          </button>
          <button type="button" class="btn btn-primary" @click="onSaveProductsPortfolioSettings" :disabled="isSaving" v-if="productWeights.length > 0">
            <i class="fa fa-save" v-if="!isSaving"></i> 
            <i class="fa fa-spin fa-spinner" v-if="isSaving"></i> 
            Save
          </button>
        </div>
        
       
      </form>
    </b-modal>

  </Layout>
</template>